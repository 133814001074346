// CreateKnowledgeBase.js
import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Paper } from '@mui/material';
import StepDefineKnowledgeBase from './StepDefineKnowledgeBase';
import StepAddSource from './StepAddSource';
import { addKnowledgeBase } from '@/api/knowledgeBase';
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {goToRoute} from "@/store/actions/navigationActions";
import Loader from "@/components/shared/Loader";
import {setToast} from "@/store/slices/toastSlice"; // Assuming this is the API function

const steps = ['Define Knowledge Base', 'Add Source'];

const CreateKnowledgeBase = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        title: '',
        // tagline: '',
        // description: '',
        category: '',
        source_type: '',
        data_sources: []
    });
    const [isValidSelection, setIsValidSelection] = useState(false);  // New state


    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        updateSelectionValidity(formData)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFormDataChange = (data) => {
        setFormData({ ...formData, ...data });
        updateSelectionValidity(data);
    };

    const updateSelectionValidity = (newData) => {
        const updatedFormData = { ...formData, ...newData };

        // Determine if all required fields in Step 1 are filled
        if (activeStep === 0) {
            if (updatedFormData.title && updatedFormData.category && updatedFormData.source_type) {
                setIsValidSelection(true);
            } else {
                setIsValidSelection(false);
            }
        }
        // Check selections based on source type for Step 2
        else if (activeStep === 1) {
            if (updatedFormData.data_sources && updatedFormData.data_sources.length > 0) {
                setIsValidSelection(true);
            } else {
                setIsValidSelection(false);
            }
        }
    };

    const handleSubmit = async () => {
        setLoading(true)
        const payload = {
            ...formData,
        };
        try {
            const accessToken = await getAccessTokenSilently();
            await addKnowledgeBase(accessToken, payload);

            setLoading(false);
            dispatch(goToRoute(`/`));
            dispatch(setToast({
                message: "Knowledge Base created",
                toasterColor: 'success'
            }));

        } catch(error) {
            console.error('Error:', error);
            setLoading(false);
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        }
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <StepDefineKnowledgeBase formData={formData} onChange={handleFormDataChange} />;
            case 1:
                return <StepAddSource formData={formData} onChange={handleFormDataChange} />;
            default:
                return 'Unknown step';
        }
    };
    if (loading) {
        return <Loader message={"Something good is happening..."}/>
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', height:'95vh', flexDirection: 'column'}} component="main">
            <Paper sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 3 }}>
                {/*<Typography variant="h3" component="h5" sx={{ ml:1,  textAlign: 'center' }}>*/}
                {/*    Create Knowledge Base*/}
                {/*</Typography>*/}
                <Stepper activeStep={activeStep} alternativeLabel sx={{ flexGrow:1 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>

            <Paper sx={{
                marginTop:3,
                paddingTop: 3,
                overflowY: 'auto',
                flexBasis: 'auto',
                flexGrow: 1,
                borderRadius: 2,
                paddingBottom: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'

            }}>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row',
                    padding: 3, justifyContent: 'space-between' }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!isValidSelection}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default CreateKnowledgeBase;
