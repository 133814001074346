import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import { createConversation, getMessages, sendMessage as apiSendMessage } from '@/api/conversation';
import {Box, Typography} from '@mui/material';
import Loader from '@/components/shared/Loader';
import { TypingIndicator } from "@/components/modules/Conversation/partials/TypingIndicator";
import MessageCard from "@/components/modules/Conversation/partials/MessageCard";
import { ChatInput } from "@/components/modules/Conversation/partials/ChatInput";
import ConversationHeader from "@/components/modules/Conversation/partials/Header";
import {useDispatch, useSelector} from "react-redux";
import { setToast } from "@/store/slices/toastSlice";
import {
    appendConversationsData,
    setSelectedConversationId,
    setConversationCreated,
    clearSelectedConversationId
} from "@/store/slices/conversationSlice";
import {goToRoute} from "@/store/actions/navigationActions";
import KnowledgeBaseInfoModal from "@/components/shared/Modals/knowledgeBaseInfoModal";
import {getKnowledgeBase} from "@/api/knowledgeBase";

const Conversation = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const conversationCreated = useSelector((state) => state.conversations.conversationCreated);
    const selectedConversationId = useSelector((state) => state.conversations.selectedConversationId);

    const dispatch = useDispatch();
    const { kbId, conversationId } = useParams();

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [userName, setUserName] = useState('');
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [kbInfoModalOpen, setKbInfoModalOpen] = useState(false);
    const [knowledgeBaseInfo, setKnowledgeBaseInfo] = useState(null);
    const [kbInfoLoading, setKbInfoLoading] = useState(false);
    const [plotSwitch, setPlotSwitch] = useState(false);
    const [citationSwitch, setCitationSwitch] = useState(false);
    const [scroll, setScroll] = useState(false);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({  block: 'end', });
        const anchor = (document).querySelector(
            '#scroll-point',
        );

        if (anchor) {
            console.log('scrolling into view')
            anchor.scrollIntoView({
                block: 'end',
                // behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (scroll) {
            scrollToBottom();
            setScroll(false);
        }
    }, [scroll]);

    useEffect(() => {
        if (conversationId && conversationId !== selectedConversationId) {
            dispatch(setSelectedConversationId(conversationId));
            dispatch(setConversationCreated(false));
        }
        scrollToBottom();
    }, [conversationId]);


    useEffect(() => {
        setUserName(user.name.toUpperCase());
    }, [user]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                setIsLoading(true);
                const accessToken = await getAccessTokenSilently();
                const res = await getMessages(selectedConversationId, accessToken);
                if (res.data) {
                    const conMessages = res.data.messages;
                    const transformedMessages = conMessages.map(item => ({
                        text: item.content,
                        isUser: item.is_bot !== true, // Assuming `is_bot` being true means it's not from the user
                        id: item._id,
                        citations: item.citations ? item.citations : null
                    }));
                    const reverseMessages = [...transformedMessages].reverse();
                    setMessages(reverseMessages);
                    setIsLoading(false);
                    // scrollToBottom();
                    setScroll(true);
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                dispatch(setToast({
                    message: e.message,
                    toasterColor: 'error'
                }));
            }
        };

        if (selectedConversationId && !conversationCreated) {
            fetchMessages();
        }
    }, [selectedConversationId, getAccessTokenSilently, dispatch, conversationCreated]);

    useEffect( () =>{
        setIsLoading(true);
        const getKbInfo = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await getKnowledgeBase(accessToken, kbId);
                // console.log(response.data)
                setKnowledgeBaseInfo(response.data);
                setIsLoading(false);
                setKbInfoLoading(false);
            } catch (error) {
                console.error('Error fetching knowledge base information:', error);
                setIsLoading(false);

                setKbInfoLoading(false);
            }
        };
        getKbInfo();

    }, [getAccessTokenSilently, kbId]);

    const sendMessage = async () => {
        if (input.trim()) {
            const newMessage = { id: messages.length + 1, text: input, isUser: true };
            setMessages([...messages, newMessage]);
            setInput('');
            let newConversationId = conversationId;

            if (!conversationId) {
                const conPayload = {
                    kb_id: kbId,
                    title: knowledgeBaseInfo.title
                };
                const accessToken = await getAccessTokenSilently();
                const response = await createConversation(accessToken, conPayload);

                newConversationId = response.data.conversation._id;
                dispatch(appendConversationsData(response.data.conversation));
                dispatch(setConversationCreated(true));
                dispatch(setSelectedConversationId(newConversationId));
                const newUrl = `/kb/${kbId}/c/${newConversationId}`;
                dispatch(goToRoute(newUrl, {}, null, true))
            }

            const payload = {
                kb_id: kbId,
                conversation_id: newConversationId,
                question: input,
                plot_mode: plotSwitch, // Include plot switch state
                citation_required: citationSwitch, // Include citation switch state
            };

            setIsBotTyping(true);
            // scrollToBottom();
            setScroll(true);

            try {
                const accessToken = await getAccessTokenSilently();
                const response = await apiSendMessage(payload, accessToken);

                const botMessage = {
                    id: messages.length + 2,
                    text: response.data.message.content, // Adjust according to your response structure
                    isUser: false,
                    citations: response.data.message.citations ? response.data.message.citations : null
                };
                setMessages([...messages, newMessage, botMessage]);
                setIsBotTyping(false);
                // scrollToBottom();
                setScroll(true);
            } catch (error) {
                console.error('Error sending message:', error);
                setIsBotTyping(false);
            }
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage();
        }
    };

    const handleNewChatClicked = () => {
        dispatch(clearSelectedConversationId());
        setMessages([]);
        dispatch(goToRoute(`/kb/${kbId}/c`));
        // navigate(`/kb/${kbId}/c`);
    };

    const handleAboutClicked = async () => {
        setKbInfoModalOpen(true);
    }


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '95vh',
        }}>
            <ConversationHeader
                knowledgeBaseName={knowledgeBaseInfo?.title ? knowledgeBaseInfo.title : 'KnowledgeBase'}
                knowledgeBaseSourceType={knowledgeBaseInfo?.source_type}
                handleNewChatClicked={handleNewChatClicked}
                handleAboutClicked={handleAboutClicked}
                onPlotSwitchChange={setPlotSwitch}
                onCitationSwitchChange={setCitationSwitch}
            />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '95vh',
                overflow: 'hidden',
            }} pb={1}>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                p: 3,
                                mb: 2,
                                maxHeight: 'calc(95vh - 150px)',
                                width: '100%',
                            }}
                        >
                            {messages.length === 0 ? (
                                <Box sx={{
                                    height: 'calc(100vh - 100px)',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="h2" component="p">How can I help you today?</Typography>
                                </Box>

                            ) : (
                                messages.map((msg, index) => (
                                    <MessageCard
                                        key={index}
                                        msg={msg}
                                        index={index}
                                        userName={userName}
                                    />
                                ))
                            )}
                            {isBotTyping && <TypingIndicator/>}
                            <div ref={messagesEndRef}/>

                        </Box>
                        <ChatInput
                            handleKeyPress={handleKeyPress}
                            handleInputChange={handleInputChange}
                            handleMessageSend={sendMessage}
                            input={input}
                        />
                    </>
                )}
            </Box>
            <KnowledgeBaseInfoModal
                open={kbInfoModalOpen}
                handleClose={() => setKbInfoModalOpen(false)}
                knowledgeBaseInfo={knowledgeBaseInfo}
                loading={kbInfoLoading}
            />
        </Box>
    );
};

export default Conversation;
