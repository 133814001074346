// KnowledgeBase.js
import React, { useState, useEffect } from 'react';

import 'ldrs/trefoil'

import KnowledgeBaseEmptyState from './partials/EmptyScreen';
import ListKnowledgeBases from './partials/ListKnowledgeBase';
import {deleteKnowledgeBase, getKnowledgeBases} from '@/api/knowledgeBase';

import Loader from '@/components/shared/Loader'
import ConfirmationDialog from "@/components/shared/ConfirmModal";
import {useAuth0} from "@auth0/auth0-react";
import {setToast} from "@/store/slices/toastSlice";
import {useDispatch} from "react-redux";
import {goToRoute} from "@/store/actions/navigationActions";
import { clearSelectedConversationId } from "@/store/slices/conversationSlice";



const KnowledgeBase = () => {
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearSelectedConversationId());
    }, []);


    useEffect(() => {
        setLoading(true);
        const fetchKbs = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                getKnowledgeBases(accessToken).then(data => {
                    setKnowledgeBases(data.data);
                    setLoading(false);
                }).catch(error => {
                    console.error('Failed to fetch knowledge bases:', error);
                    setLoading(false);
                });
            } catch (error) {

            }
        }
        fetchKbs();
    }, [getAccessTokenSilently]);

    if (loading) {
        return <Loader message={"Loading Knowledge Bases"}/>
    }

    const handleDeleteClick = (itemId) => {
        console.log(`delete clicked for item: ${itemId}`)
        setSelectedItemId(itemId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        console.log(`Deleting item with id: ${selectedItemId}`);
        // Add API call to delete item here
        try {
            setLoading(true);
            const token = await getAccessTokenSilently();
            deleteKnowledgeBase(token, selectedItemId)
                .then(data => {
                    // Filter out the deleted item from the state
                    setKnowledgeBases(prevKnowledgeBases =>
                        prevKnowledgeBases.filter(kb => kb._id !== selectedItemId)
                    );
                    setLoading(false);
                }).catch(error => {
                    console.error('Failed to delete knowledge base:', error);
                    setLoading(false);
                    dispatch(setToast({
                        message: error.message,
                        toasterColor: 'error'
                    }));
                });
        } catch (e) {
            console.error('An error occurred:', e);
            setLoading(false);
            dispatch(setToast({
                message: e.message,
                toasterColor: 'error'
            }));
        }

        setDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleEdit = (knowledgeBaseId) => {
        dispatch(goToRoute(`/kb/${knowledgeBaseId}/d`)); // Navigate to the edit page
    };

    const handleCardClick = (knowledgeBaseId) => {
        dispatch(goToRoute(`/kb/${knowledgeBaseId}/c`)); // Navigate to the create conversation page
    };

    const handleAddKnowledgeClick = () => {
        console.log('add knowledge clicked')
        dispatch(goToRoute('/knowledge/create'));
    }

    return (
        <>
            {knowledgeBases.length > 0 ? (
                <>
                    <ListKnowledgeBases
                        items={knowledgeBases}
                        handleAddKnowledgeClick={handleAddKnowledgeClick}
                        handleDeleteClick={handleDeleteClick}
                        handleDeleteConfirm={handleDeleteConfirm}
                        handleDeleteCancel={handleDeleteCancel}
                        handleEdit={handleEdit}
                        handleCardClick={handleCardClick}
                        dialogOpen={deleteDialogOpen}
                    />

                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onConfirm={handleDeleteConfirm}
                        onCancel={handleDeleteCancel}
                        title="Confirm Delete"
                        description="Are you sure you want to delete this knowledge base?"
                    />
                </>


            ) : (
                <KnowledgeBaseEmptyState handleClick={handleAddKnowledgeClick}/>
            )}

        </>
    );
};

export default KnowledgeBase;
