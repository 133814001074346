// import dotenv from 'dotenv';

import prod from './production.js';
import defaultConfig from './default';

// dotenv.config();

let envConfig = null;
let env = process.env.APP_ENV;

switch (env) {
    case 'prod':
    case 'production':
        envConfig = { ...prod };
        break;
    default:
        envConfig = { ...defaultConfig};
}

export default envConfig;