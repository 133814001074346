// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { HistoryRouter } from "redux-first-history/rr6";
import { Auth0ProviderWithNavigate } from "@/utils/auth0-provider-with-navigate";
import RouterProvider from './Router';
import theme from '@/utils/theme';
import { CssBaseline } from '@mui/material';

class Root extends Component {
    render() {
        const { store, history } = this.props;
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <HistoryRouter history={history}>
                            <Auth0ProviderWithNavigate>
                                <RouterProvider />
                            </Auth0ProviderWithNavigate>
                        </HistoryRouter>
                    </StyledEngineProvider>
                </ThemeProvider>
            </Provider>
        );
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Root;
