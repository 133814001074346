import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, CircularProgress, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { useTheme } from "@mui/material/styles";

import { getSignedUrl } from '@/utils/s3_utils'; // Adjust the import path as necessary

import "@cyntler/react-doc-viewer/dist/index.css";
import "./document.modal.css";

const DocumentModal = ({ isOpen, onClose, documentUrl }) => {
    const [loading, setLoading] = useState(false);
    const [signedUrl, setSignedUrl] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        const fetchSignedUrl = async () => {
            try {
                setLoading(true);
                const url = await getSignedUrl(documentUrl);

                // Fetch the URL to debug
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Error fetching document: ${response.statusText}`);
                }
                setSignedUrl(url);
                setLoading(false);
            } catch (error) {
                console.error('Error generating signed URL:', error);
                setLoading(false);
            }
        };

        if (isOpen && documentUrl) {
            fetchSignedUrl();
        }
    }, [isOpen, documentUrl]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            sx={{
                padding: theme.spacing(2),
            }}
            PaperProps={{
                sx: {
                    width: '60%',
                    maxWidth: '90vw',
                    height: '650px',
                    maxHeight: '90vh',
                    borderRadius: '16px'
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 4 }} >
                {documentUrl?.substring(documentUrl.indexOf('/') + 1)}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {loading ? (
                    <CircularProgress />
                ) : signedUrl ? (
                    <DocViewer
                        documents={[{ uri: signedUrl }]}
                        prefetchMethod="GET"
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            pdfVerticalScrollByDefault: true,
                            header: {
                                disableHeader: true
                            }
                        }}
                        theme={{
                            primary: theme.palette.primary.light,
                            secondary: theme.palette.secondary.main,
                            textTertiary: "#00000099",
                            disableThemeScrollbar: true,
                        }}
                    />
                ) : (
                    <div>No document found</div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DocumentModal;
