import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {getConfig} from "@/utils/config";
import {Auth0ContextProvider} from "@/utils/auth0-context-provider";

const config = getConfig();

export const Auth0ProviderWithNavigate = ({ children }) => {
    const navigate = useNavigate();

    const domain = config.domain;
    const clientId = config.clientId;
    const redirectUri = config.redirectUri;
    const audience = config.audience;

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <Auth0ContextProvider>
                {children}
            </Auth0ContextProvider>
        </Auth0Provider>
    );
};