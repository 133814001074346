// KnowledgeBaseEmptyState.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const KnowledgeBase = ( {handleClick} ) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            textAlign="center"
            p={2}
        >
            {/*<Typography variant="h4" component="h1" gutterBottom>*/}
            {/*    Knowledge Bases*/}
            {/*</Typography>*/}
            {/*<Typography variant="subtitle1" color="textSecondary" gutterBottom>*/}
            {/*    You can create a knowledge base using data sources and chat with and perform other actions.*/}
            {/*</Typography>*/}
            <Box mt={4}>
                <Typography variant="subtitle1">
                    No knowledge bases
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    You haven't created any knowledge bases yet.
                </Typography>
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleClick}
                >
                    Add Knowledge Base
                </Button>
            </Box>
        </Box>
    );
};

export default KnowledgeBase;
