import { createSlice } from '@reduxjs/toolkit';

const initialState = { toast: null };

const toastSlice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        setToast(state, action) {
            state.toast = action.payload;
        },
    },
});

export const { setToast } = toastSlice.actions;
export default toastSlice.reducer;
