// StepAddSource.js

import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import FileSource from "@/components/modules/KnowledgeBase/partials/CreateKnowledgeBase/FilesSource";
import DatabaseSource from './DatabaseSource';
import {useSelector} from "react-redux";
import URLSource from "@/components/modules/KnowledgeBase/partials/CreateKnowledgeBase/URLSource";

const StepAddSource = ({ formData, onChange }) => {
    const sources = useSelector((state) => state.sources.sources)

    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedDatabase, setSelectedDatabase] = useState([]);
    const [selectedUrls, setSelectedUrls] = useState([]); // State for selected URLs

    // Filter sources based on source_type
    const fileSources = sources.filter(source => source.source_type === 'file');
    const databaseSources = sources.filter(source => source.source_type === 'database');
    const urlSources = sources.filter(source => source.source_type === 'url'); // Filter URL sources


    useEffect(() => {
        console.log(selectedFiles)
        onChange({ data_sources: selectedFiles });
    }, [selectedFiles]);

    useEffect(() => {
        console.log(selectedDatabase)
        onChange({ data_sources: selectedDatabase });
    }, [selectedDatabase]);


    useEffect(() => {
        console.log(selectedUrls);
        onChange({ data_sources: selectedUrls }); // Update form data with selected URLs
    }, [selectedUrls]);


    const handleDatabaseChange = (addedDatabase) => {
        const updatedDatabases = [...selectedDatabase, addedDatabase];
        setSelectedDatabase(updatedDatabases);
        onChange({ database: addedDatabase.id }); // Assuming database objects have an 'id' property
    };


    return (
        <Box sx={{ overflowY: 'auto', flexBasis: 'auto', flexGrow: 1 }}>
            {formData.source_type === 'file' ? (
                <FileSource selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} files={fileSources} />
            ) : formData.source_type === 'database' ? (
                <DatabaseSource selectedDatabase={selectedDatabase} setSelectedDatabase={setSelectedDatabase} handleDatabaseChange={handleDatabaseChange} databases={databaseSources} />
            ) : formData.source_type === 'url' ? (  // Add URL source condition
                <URLSource selectedUrls={selectedUrls} setSelectedUrls={setSelectedUrls} urls={urlSources} />
            ) : (
                <Typography variant="h6">Please select a source type in the previous step.</Typography>
            )}
        </Box>
    );
};

export default StepAddSource;

