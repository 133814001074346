import React, { useState } from 'react';
import {
    useMaterialReactTable,
    MaterialReactTable,
} from 'material-react-table';
import { Box, Button, ButtonGroup, MenuItem,
    Grow,
    Popper,
    Paper,
    MenuList,
    ClickAwayListener,

} from '@mui/material';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddFilesModal from "@/components/shared/Modals/addFiles";
import AddDatabasesModal from "@/components/shared/Modals/addDatabase"
import AddURLsModal from "@/components/shared/Modals/addUrlsModal";
import {useSelector} from "react-redux";


const options = ['Files', 'Database', 'URLs'];


const ListDataSources = () => {
    const [loading, setLoading] = useState(true);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [dbModalOpen, setDbModalOpen] = useState(false);
    const [urlModalOpen, setUrlModalOpen] = useState(false);
    const anchorRef = React.useRef(null);

    const sources = useSelector((state) => state.sources.sources)


    const handleClick = (option) => {
        console.info(`You clicked ${option}`);
        if (option === 'Files') {
            setFileModalOpen(true)
        }
        if (option === 'Database') {
            setDbModalOpen(true)
        }
        if (option === 'URLs') {
            setUrlModalOpen(true)
        }
    };

    const handleMenuItemClick = (event, option) => {
        setOpen(false);
        handleClick(option); // Call the handleClick function when an option is clicked
    };

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);

    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const columns = [
        {
            accessorKey: 'source_name',
            header: 'Name',
        },
        {
            accessorKey: 'source_type',
            header: 'Type',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => <span>{cell.getValue() ? '✅' : '❌'}</span>,
        },
        {
            accessorKey: 'created_at',
            header: 'Added',
            Cell: ({ cell }) => {
                const date = new Date(cell.getValue());
                return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                }); // Format date to show only the date part
            },
        }
    ];

    const table = useMaterialReactTable({
        columns,
        data: sources,
        enableRowSelection: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
            showGlobalFilter: true
        },
        muiTablePaperProps: {
            elevation: 0
        },
        state: {
            loading,
        },
        renderTopToolbarCustomActions:() => {
            return (
                <Box mb={3}>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                    >
                        <Button
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            startIcon={<StorageRoundedIcon/>}
                            endIcon={<ArrowDropDownIcon />}
                            onClick={handleToggle}
                        >Add Source
                            {/*<ArrowDropDownIcon />*/}
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{ zIndex: 1200 }}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => {
                                                const label = (option === 'Database') ? `${option} (Coming Soon)` : option;
                                                const isDisabled = option === 'Database';

                                                return (
                                                    <MenuItem
                                                        sx={{
                                                            padding: '0.85rem 2.5rem',
                                                            margin: '8px',
                                                            borderRadius: '8px',
                                                            fontWeight: '600',
                                                            color: isDisabled ? 'grey.500' : 'text.primary'
                                                        }}
                                                        key={index}
                                                        onClick={(event) => !isDisabled && handleMenuItemClick(event, option)}
                                                        disabled={isDisabled}
                                                    >
                                                        {label}
                                                    </MenuItem>
                                                );
                                            })}
                                            {/*{options.map((option, index) => (*/}
                                            {/*    */}
                                            {/*    <MenuItem*/}
                                            {/*        sx={{*/}
                                            {/*            padding: '0.85rem 2.5rem',*/}
                                            {/*            margin: '8px', borderRadius: '8px',*/}
                                            {/*            fontWeight: '600'*/}
                                            {/*        }}*/}
                                            {/*        key={index}*/}
                                            {/*        onClick={(event) => handleMenuItemClick(event, option)}*/}
                                            {/*    >*/}
                                            {/*        {option}*/}
                                            {/*    </MenuItem>*/}
                                            {/*))}*/}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>

            )

        },
    });

    return (
        <Box sx={{  padding: '0px', display: 'flex', flexDirection: 'column'}}>
            <MaterialReactTable table={table} />
            <AddFilesModal open={fileModalOpen} handleClose={() => setFileModalOpen(false)} />
            <AddDatabasesModal open={dbModalOpen} handleClose={() => setDbModalOpen(false)} />
            <AddURLsModal open={urlModalOpen} handleClose={() => setUrlModalOpen(false)}/>
        </Box>
    );
};

export default ListDataSources;
