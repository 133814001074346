import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Snackbar, Alert as MuiAlert} from '@mui/material';
// import { Alert as MuiAlert } from '@mui/lab';

// styles
// import { useStyles } from './styles';

const Toast = ({ severity, autoHideDuration, title, onCloseCallback, anchorOrigin, ...props }) => {
    // const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            setOpen(false);
            onCloseCallback && onCloseCallback();
        }
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={autoHideDuration}
            anchorOrigin={anchorOrigin}
        >
            <MuiAlert
                severity={severity}
                onClose={handleClose}
                variant="filled"
                sx={{
                    borderRadius: '10px',
                }}
                {...props}
            >
                {title}
            </MuiAlert>
        </Snackbar>
    );
};

export default Toast;

Toast.propTypes = {
    title: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
    onCloseCallback: PropTypes.func,
    anchorOrigin: PropTypes.shape({
        vertical: PropTypes.oneOf(['bottom', 'top', 'center']),
        horizontal: PropTypes.oneOf(['left', 'right', 'center']),
    }),
};
Toast.defaultProps = {
    autoHideDuration: 5000,
    onCloseCallback: null,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};
