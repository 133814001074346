//ListKnowledgeBaseHeader.js
import {Box, Button, IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import getIcon from '@/utils/get-icon'


const ListKnowledgeBaseHeader = ({ onSearchChange, searchValue, handleAddKnowledgeClick }) => {
    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Paper
                    component="form"
                    sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '60%',
                        // marginBottom: 2
                    }}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search knowledge bases"
                        inputProps={{ 'aria-label': 'search  knowledge bases' }}
                        value={searchValue}
                        onChange={(e) => onSearchChange(e.target.value)}
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <Box>
                    <Button
                        startIcon={getIcon('add')}
                        color="primary"
                        variant="contained"
                        endIcon={getIcon('knowledge')}
                        sx={{
                            fontWeight: 700
                        }}
                        onClick={() => handleAddKnowledgeClick()}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        </>

    )
}

export default ListKnowledgeBaseHeader;