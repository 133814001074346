import React from 'react';
import { DialogTitle, Typography, Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';

const ShareDialogTitle = ({ title, handleClose }) => {
    return (
        <DialogTitle>
            <Box display="flex" alignItems="center" p={1}>
                <ShareIcon sx={{ mr: 1 }} fontSize='large' />
                <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                    Share {title}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
    );
};

export default ShareDialogTitle;
