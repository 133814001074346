import { updateConversationsData } from '@/store/slices/conversationSlice';
import { getConversations } from '@/api/conversation';
import { goToRoute } from './navigationActions';
import { setToast } from '@/store/slices/toastSlice';

export const ConversationDataGetAndStore = (accessToken) => {
    return (dispatch) => {
        accessToken
            ? getConversations(accessToken)
                .then((res) => {
                    if (res.data) {
                        dispatch(updateConversationsData(res.data));
                    }
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(
                        setToast({
                            message: e.message,
                            toasterColor: 'error',
                        })
                    );
                })
            : dispatch(goToRoute('/login'));
    };
};