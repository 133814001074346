import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Box, Button, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {uploadFiles} from "@/api/dataSource";
import { appendSourcesData } from "@/store/slices/sourceSlice";

const AddFilesModal = ({ open, handleClose }) => {
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [uploading, setUploading] = React.useState(false);

    const onDrop = async (acceptedFiles) => {
        console.log(acceptedFiles)
        setUploading(true);
        const formData = new FormData();
        acceptedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await uploadFiles(accessToken, formData);
            const responseData = response.data;
            dispatch(appendSourcesData(responseData)); // Update the state with response data
            setUploading(false);
            handleClose(); // Close the modal after uploading
        } catch (error) {
            console.error('Error:', error);
            setUploading(false);
        }
    };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop, disabled: uploading });

    // const handleBrowseFileChange = (event) => {
    //     console.log(event.target.files); // Here you would handle file uploading
    //     onDrop(event.target.files);
    // };

    return (
        <Modal
            open={open}
            onClose={() => { if (!uploading) handleClose(); }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                // width: fullScreen ? 'auto' : 400,
                // maxHeight: '90vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                pt: 2,
                pb: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        visibility: uploading ? 'hidden' : 'visible'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Files
                </Typography>
                <Box {...getRootProps()} sx={{
                    // border: '2px dashed',
                    borderColor: 'divider',
                    mt: 2,
                    mb: 2,
                    p: 4,
                    textAlign: 'center',
                    cursor: uploading ? 'not-allowed' : 'pointer',
                    borderRadius: '4px',
                    width: '100%', // Ensures that the dropzone is responsive
                    backgroundColor: isDragActive ? 'action.hover' : 'background.paper'
                }}>
                        <Box sx={{ width: '100%', height: 'auto' }}>
                            <img src={`${process.env.PUBLIC_URL}/upload_background.png`} alt="Upload background" style={{ width: '50%'}}/>
                        </Box>
                    <Button variant="contained" component="label" disabled={uploading} sx={{ mt: 2 }}>
                        Browse
                        <input
                            type="file"
                            hidden
                            multiple
                            onChange={(event) => {
                                if (!uploading) { // Prevent file selection if already uploading
                                    onDrop(event.target.files);
                                }
                            }}
                            disabled={uploading}
                        />
                    </Button>
                    <input {...getInputProps()} disabled={uploading} />
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        or drag the files here...
                    </Typography>

                </Box>
                {uploading && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}>
                        <CircularProgress />
                    </Box>
                )}

            </Box>
        </Modal>
    );
};

export default AddFilesModal;
