import React, {useState} from 'react';
import {
    DialogContent,
    TextField,
    Button,
    Box,
    Typography,
    Chip,
    Divider,
    InputAdornment, Avatar
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from "@mui/icons-material/Search";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AllInbox from "@mui/icons-material/AllInbox";
import ShareIcon from "@mui/icons-material/Share"

const ShareDialogContent = ({
                                email,
                                setEmail,
                                emailError,
                                setEmailError,
                                handleAddEmail,
                                addedEmails,
                                handleDeleteEmail,
                                sharedUsers,
                                handleRemoveAccess,
                                handleAddAllEmails,
                                owner
                            }) => {
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    return (
        <DialogContent dividers>
            <Box display="flex" alignItems="center" mb={3}>
                <Button
                    startIcon={<AllInbox sx={{ color: 'blue' }} />}
                    sx={{
                        textTransform: 'none',
                        backgroundColor: '#F0F0FF',
                        color: 'blue',
                        mr: 2
                    }}
                    onClick={() => { }}
                >
                    Collaborate
                </Button>
            </Box>
            <Box display="flex" alignItems="flex-start" mb={2}>
                <Autocomplete
                    freeSolo
                    fullWidth
                    openOnFocus={false}
                    options={email ? [...new Set([email, ...addedEmails])] : []}
                    inputValue={email}
                    value={autocompleteValue}
                    onInputChange={(event, newInputValue) => {
                        setEmail(newInputValue);
                        setEmailError('');
                    }}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            handleAddEmail(newValue);
                            setAutocompleteValue(null);
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            p={3}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#F0F0FF',
                                borderRadius: 1,
                                marginBottom: 1.5,
                                marginRight: 3,
                                marginLeft: 3
                            }}
                        >
                            <EmailIcon sx={{ mr: 1, mt: 1, mb: 1 }} />
                            <Typography>{option}</Typography>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            placeholder="Add emails"
                            size="small"
                            error={!!emailError}
                            helperText={emailError}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <Button
                    variant="contained"
                    sx={{ marginLeft: '24px' }}
                    startIcon={<ShareIcon />}
                    onClick={() => {
                        handleAddAllEmails();
                        setAutocompleteValue(null);
                    }}
                    disabled={addedEmails.length === 0}
                >
                    Share
                </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                {addedEmails.map((addedEmail) => (
                    <Chip
                        key={addedEmail}
                        icon={<EmailIcon />}
                        label={addedEmail}
                        onDelete={() => handleDeleteEmail(addedEmail)}
                    />
                ))}
            </Box>
            <Divider variant="middle" />
            <Typography variant="h3" component="div" sx={{ mt: 2, mb: 1 }}>
                Shared with
            </Typography>
            {sharedUsers && sharedUsers.map((user, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ width: 32, height: 32 }} src={user.picture} alt={user.name} />
                        <Box>
                            <Typography variant="subtitle2">{user.name}</Typography>
                            <Typography variant="caption" color="text.secondary">
                                {user.email}
                            </Typography>
                        </Box>
                    </Box>
                    {
                        owner === true && <Button
                            variant="outlined"
                            size="small"
                            startIcon={<PersonRemoveIcon />}
                            onClick={() => handleRemoveAccess(user.email)}
                        >
                            Remove
                        </Button>
                    }

                </Box>
            ))}
        </DialogContent>
    );
};

export default ShareDialogContent;
