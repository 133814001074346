let config = {
    envName: 'production',
    knowledgeApi: 'https://api.conversatio.io/v1/kb',
    conversationApi: 'https://api.conversatio.io',
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: 'eu-west-2',
    bucketName: 'kb-datasource-files',
    domain: "auth.conversatio.io",
    clientId: process.env.REACT_APP_CLIENT_ID,
    audience: "https://conversation.uk.auth0.com/api/v2/",
    redirectUri: "https://app.conversatio.io"
};

export default config;