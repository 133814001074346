import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
    useMaterialReactTable,
    MaterialReactTable
} from 'material-react-table';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddURLsModal from '@/components/shared/Modals/addUrlsModal'; // Import the URL modal

const columns = [
    {
        accessorKey: '_id',
        header: 'Id',
    },
    {
        accessorKey: 'source_name',
        header: 'Name',
        size: 100,
        // size: '40%',  // Set a fixed width for the column
        muiTableBodyCellProps: {
            sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    },
    {
        accessorKey: 'source_type',
        header: 'Type',
        size: 50
    },
    {
        accessorKey: 'updated_at',
        header: 'Last-Modified',
        render: rowData => new Date(rowData.modified_at).toLocaleDateString(),
    },
];

const URLSource = ({ selectedUrls, setSelectedUrls, urls }) => {
    const [urlModalOpen, setUrlModalOpen] = useState(false);
    const [rowSelection, setRowSelection] = useState({});

    const handleClick = () => {
        setUrlModalOpen(true);
    };

    useEffect(() => {
        console.log(selectedUrls);
    }, [selectedUrls]);

    const table = useMaterialReactTable({
        columns,
        data: urls, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableRowSelection: true,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner: "bottom",
        muiTablePaperProps: {
            elevation: '0'
        },
        initialState: {
            pagination: { pageSize: 10, pageIndex: 0 },
            showGlobalFilter: true,
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 25],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow._id, //give each row a more useful id
        onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
        state: { rowSelection },
        renderTopToolbarCustomActions: () => {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    sx={{ ml: '1rem' }}
                    onClick={handleClick}
                >
                    Add URLs
                </Button>
            );
        }
    });

    useEffect(() => {
        setSelectedUrls(Object.keys(rowSelection));
    }, [rowSelection]);

    return (
        <>
            <MaterialReactTable table={table} />
            <AddURLsModal open={urlModalOpen} handleClose={() => setUrlModalOpen(false)} />
        </>
    );
};

export default URLSource;
