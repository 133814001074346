import {Box} from "@mui/material";
import React from "react";
import { quantum } from 'ldrs'
import "./styles.css"
import {useTheme} from "@mui/material/styles";

quantum.register()



export const TypingIndicator = () => {
    const theme = useTheme();
    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', mt: 8}}>
                <l-quantum
                    size="45"
                    speed="1.75"
                    color={theme.palette.primary.main}
                ></l-quantum>
            </Box>
        </>
    )

}




