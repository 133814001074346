import instance from './instances/conversation'

export function getConversations(accessToken) {
    return instance(accessToken).get('/v1/conversation');
}

export function getMessages(conversation_id, accessToken) {
    return instance(accessToken).get(`/v1/conversation/${conversation_id}`);
}

export function getConversation(conversation_id, accessToken) {
    return instance(accessToken).get(`/v1/conversation/${conversation_id}`);
}

export function createConversation(accessToken, payload) {
    return instance(accessToken).post('/v1/conversation', payload)
}

export function sendMessage(payload, accessToken) {
    return instance(accessToken).post('/v1/message', payload)
}

export function renameConversation(payload, accessToken, cId) {
    return instance(accessToken).patch(`/v1/conversation/${cId}`, payload)
}

export function deleteConversationApi(accessToken, cId) {
    return instance(accessToken).delete(`/v1/conversation/${cId}`)
}