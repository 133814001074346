import React, {useEffect} from 'react';
import { Route, Routes } from "react-router-dom";
import {ConversationDataGetAndStore} from '@/store/actions/conversationActions'
import { SourceDataGetAndStore } from '@/store/actions/dataSourceActions'

import {Box} from '@mui/material';

import 'ldrs/trefoil'


import useAuth from "@/hooks/useAuth";
import DefaultLayout from "@/components/layout";
import platformRoutes from "@/routes";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import Toast from "@/components/shared/Toast";
import {setToast} from "@/store/slices/toastSlice";


let RouterProvider = function () {
    const dispatch = useDispatch();
    const toast = useSelector((state) => state.toaster.toast);

    const { isLoading, error } = useAuth();
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        let isMounted = true;

        const getData = async () => {
            const accessToken = await getAccessTokenSilently();

            dispatch(ConversationDataGetAndStore(accessToken))
            dispatch(SourceDataGetAndStore(accessToken))

            if (!isMounted) {
                return;
            }
        };
        getData()


        return () => {
            isMounted = false;
        };
    }, [dispatch, getAccessTokenSilently]);

    if (isLoading) {
        return (
            <l-trefoil
                size="50"
                stroke="5"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="1.4"
                color="primary"
            ></l-trefoil>
        )
    }

    if (error) {
        return <Box>Oops... {error.message}</Box>;
    }
    return (
        <Box>
            {toast && toast.message && (
                <Toast
                    autoHideDuration={2000}
                    onCloseCallback={() => {
                        dispatch(setToast(null));
                    }}
                    title={toast.message}
                    severity={toast.toasterColor}
                />
            )}
            <DefaultLayout>
                <Routes>
                    {platformRoutes.map((value, index) => (
                        <Route
                            key={index}
                            path={value.routePath}
                            element={value.component}
                        />
                    ))}
                </Routes>
            </DefaultLayout>

        </Box>

    )
}

export default RouterProvider;