// MainPageLayout.js
import React from 'react';
import { Box } from '@mui/material';
import {useTheme} from "@mui/material/styles";

const MainPageLayout = ({ header, body }) => {
    // const theme = useTheme();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', height:'95vh', flexDirection: 'column'}} component="main">
            <Box sx={{
                // backgroundColor: theme.palette.background.dark,
                padding: 2, flexBasis: '10%', borderRadius: 2 }}>
                    {header}
            </Box>
            <Box sx={{
                // backgroundColor: theme.palette.background.dark,
                padding: 3,
                marginTop:3,
                overflowY: 'auto',
                flexBasis: 'auto',
                flexGrow: 1,
                borderRadius: 2,
                paddingBottom: 2
            }}>
                {body}
            </Box>
        </Box>
    );
};

export default MainPageLayout;
