// DatabaseSource.js
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
    useMaterialReactTable, MaterialReactTable,
} from 'material-react-table';
import { getDatabasesSources } from "@/api/knowledgeBase";
import Loader from "@/components/shared/Loader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const columns = [
    {
        accessorKey: 'id',
        header: 'Id',
    },
    {
        accessorKey: 'name',
        header: 'Name',
    },
    {
        accessorKey: 'type',
        header: 'Type',
    },
    {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
    },
];

const DatabaseSource = ({ setSelectedDatabase }) => {
    const [databases, setDatabases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowSelection, setRowSelection] = useState({});

    const getDatabases = useCallback(() => {
        setLoading(true);
        getDatabasesSources().then(databasesData => {
            setDatabases(databasesData);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching databases:', error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        getDatabases();
    }, [getDatabases]);

    const table = useMaterialReactTable({
        columns,
        data: databases, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner: "bottom",
        muiTablePaperProps: {
            elevation: '0'
        },
        initialState: {
            pagination: {pageSize: 5, pageIndex: 0},
            showGlobalFilter: true,
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow.id, //give each row a more useful id
        onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
        state: {rowSelection},
        renderTopToolbarCustomActions: () => {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon/>}
                    sx={{ml: '1rem'}}
                >
                    Add Database
                </Button>
            )
        }


    });


    useEffect(() => {
        setSelectedDatabase(Object.keys(rowSelection))
    }, [rowSelection]);

    if (loading) {
        return (<Loader/>)
    }

    return (
        <MaterialReactTable table={table}
        />
    );
}

export default DatabaseSource;
