import conversationReducer from "@/store/slices/conversationSlice";
import toasterReducer from "./slices/toastSlice";
import sourceReducer from "@/store/slices/sourceSlice"

const rootReducer = (routerReducer) => ({
    router: routerReducer,
    conversations: conversationReducer,
    toaster: toasterReducer,
    sources: sourceReducer
});

export default rootReducer;