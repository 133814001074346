import React from 'react';
import { DialogActions, Button } from '@mui/material';

const ShareDialogActions = ({ handleShare }) => {
    return (
        <DialogActions p={8}>
            <Button onClick={handleShare} variant="contained" color="primary">
                Done
            </Button>
        </DialogActions>
    );
};

export default ShareDialogActions;
