// // ListKnowledgeBaseBody.js
import React, {useEffect, useState} from 'react';
import {
    Card,
    Grid,
    Typography,
    CardContent,
    CardActions,
    Box,
    IconButton,
    CardActionArea,
    CardMedia,
    Chip,
    useTheme,
    Menu,
    MenuItem, Avatar, ListItemIcon, Divider, Button
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import getIcon from '@/utils/get-icon';
import {useAuth0} from "@auth0/auth0-react";
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import AllInbox from '@mui/icons-material/AllInbox';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import HttpsIcon from '@mui/icons-material/Https';

import { History, Person } from '@mui/icons-material';
import ShareDialog from "@/components/shared/Modals/ShareModal/index";

const ListKnowledgeBaseBody = ({
                                   items,
                                   handleDeleteClick,
                                   handleEditClick,
                                   handleCardClick,
                               }) => {
    const theme = useTheme();

    const getStatusColor = (status) => {
        switch (status) {
            case 'processing':
                return theme.palette.warning.main;
            case 'complete':
                return theme.palette.success.main;
            case 'pending':
                return theme.palette.info.main;
            case 'error':
                return theme.palette.error.main;
            default:
                return theme.palette.text.primary;
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [filter, setFilter] = useState('all');
    const [filteredItems, setFilteredItems] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [sharedList, setSharedList] = useState([]);

    const [selectedKb, setSelectedKb] = useState(null);


    const { user } = useAuth0();

    const handleMenuClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const handleEdit = (itemId) => {
        handleMenuClose();
        handleEditClick(itemId);
    };

    const handleDelete = (itemId) => {
        handleMenuClose();
        handleDeleteClick(itemId);
    };

    const handleConversation = (itemId) => {
        handleMenuClose();
        handleCardClick(itemId);
    };

    const handleOpenDialog = (selectedItem) => {
        setSelectedKb(selectedItem);
        setDialogTitle(selectedItem?.title);
        setSharedList(selectedItem?.shared_with_details);
        setDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };


    useEffect( () => {
        const newItems = items.filter((item) => {
            if (filter === 'recentlyViewed') {
                return item.updated_at;
            } else if (filter === 'createdByYou') {
                return item.user_id === user.sub;
            } else if (filter === 'shared') {
                return item.user_id !== user.sub
            }
            return true;
        });
        if (filter === 'recentlyViewed') {
            newItems.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        }
        setFilteredItems(newItems);
    }, [filter, items]);

    return (
        <>
            <Box display="flex" alignItems="center" mb={4}>
                <Button
                    startIcon={<AllInbox sx={{ color: filter === 'all' ? 'blue' : 'inherit' }} />}
                    sx={{
                        textTransform: 'none',
                        color: filter === 'all' ? 'blue' : 'inherit',
                        backgroundColor: filter === 'all' ? '#F0F0FF' : 'inherit',
                        mr: 2
                    }}
                    onClick={() => handleFilterChange('all')}
                >
                    All
                </Button>
                <Button
                    startIcon={<History sx={{ color: filter === 'recentlyViewed' ? 'blue' : 'inherit' }} />}
                    sx={{ textTransform: 'none', color: filter === 'recentlyViewed' ? 'blue' : 'inherit', backgroundColor: filter === 'recentlyViewed' ? '#F0F0FF' : 'inherit', mr: 2 }}
                    onClick={() => handleFilterChange('recentlyViewed')}
                >
                    Recently viewed
                </Button>
                <Button
                    startIcon={<Person sx={{ color: filter === 'createdByYou' ? 'blue' : 'inherit' }} />}
                    sx={{ textTransform: 'none', color: filter === 'createdByYou' ? 'blue' : 'inherit', backgroundColor: filter === 'createdByYou' ? '#F0F0FF' : 'inherit', mr: 2 }}
                    onClick={() => handleFilterChange('createdByYou')}
                >
                    Created by you
                </Button>

                <Button
                    startIcon={<ShareIcon sx={{ color: filter === 'shared' ? 'blue' : 'inherit' }} />}
                    sx={{ textTransform: 'none', color: filter === 'shared' ? 'blue' : 'inherit', backgroundColor: filter === 'shared' ? '#F0F0FF' : 'inherit', mr: 2 }}
                    onClick={() => handleFilterChange('shared')}
                >
                    Shared
                </Button>
            </Box>
            <Grid container spacing={4}>
                {filteredItems.map((knowledgeBase) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={knowledgeBase._id}>
                        <Card sx={{ backgroundColor: theme.palette.background.default, maxWidth: 345, borderRadius: 2, boxShadow: 3, mb: 3, mr: 3}}>
                            <CardActionArea
                                onClick={() => handleCardClick(knowledgeBase._id)}
                                disabled={knowledgeBase.status !== 'complete'}
                            >
                                <CardMedia
                                    component="div"
                                    sx={{ height: 140, backgroundColor: theme.palette.primary.light }}
                                    alt="Knowledge Base Cover"
                                />
                                <CardContent mt={1}>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            minHeight: '3em', // Ensures at least 2 lines of space
                                        }}
                                    >
                                        {knowledgeBase.title}
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
                                        <Chip
                                            label={knowledgeBase.status}
                                            sx={{
                                                backgroundColor: getStatusColor(knowledgeBase.status),
                                                color: theme.palette.getContrastText(getStatusColor(knowledgeBase.status)),
                                                // color: theme.palette.secondary.light,
                                                borderRadius: '0.5rem',
                                                textTransform: 'Capitalize',
                                                fontWeight: '700'
                                            }}
                                        />
                                        <Chip icon={knowledgeBase.shared_with.length > 0 ? <CoPresentIcon fontSize="24px"/>: <HttpsIcon fontSize="24px"/>}
                                              label={knowledgeBase.shared_with.length > 0 ? 'Shared': 'Private'}
                                              sx={{
                                                  borderRadius: '0.5rem',
                                                  // color: theme.palette.secondary.dark,
                                                  fontWeight: '700'
                                              }}

                                        />
                                    </Box>

                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ justifyContent: 'space-between', padding: '4px', ml: 1 }}>
                                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row'}} mb={2}>
                                    <Avatar sx={{ width: 32, height: 32, mr: 1, backgroundColor: theme.palette.primary.light }} src={user.picture} alt={user.name}/>

                                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                        <Typography textTransform='capitalize'>
                                            {  user.sub === knowledgeBase.user_id ?  "Created by You" : `Created by ${knowledgeBase.created_by_details.name}`}
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Created at: {new Date(knowledgeBase.created_at).toLocaleDateString()}
                                        </Typography>
                                    </Box>


                                </Box>
                                <IconButton
                                    size="small"
                                    aria-label="more"
                                    sx={{ ml: 2 }}
                                    aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                    onClick={(event) => handleMenuClick(event, knowledgeBase)}
                                    disabled={knowledgeBase.status !== 'complete'}
                                >
                                    <MoreVertIcon fontSize="12px"/>
                                </IconButton>
                            </CardActions>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={0}
                                slotProps={ {

                                    paper: {
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.04))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            borderRadius: '8px'
                                        },

                                    }

                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box sx={{ margin: '4px 16px 16px 16px'}}>
                                    <Typography variant="h6">
                                        {selectedItem?.title}
                                    </Typography>
                                    <Typography  variant="subtitle1" color="text.secondary">
                                        {  user.sub === selectedItem?.user_id ?  "Created by You" : `Created by ${selectedItem?.created_by_details.name}`}
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Created at: {new Date(selectedItem?.created_at).toLocaleDateString()}
                                    </Typography>

                                </Box>
                                <Divider/>
                                <MenuItem onClick={() => handleEdit(selectedItem?._id)} sx={{ margin: '8px', borderRadius: '4px' }}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body1">Edit</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => handleConversation(selectedItem?._id)} sx={{ margin: '8px', borderRadius: '4px' }}>
                                    <ListItemIcon>
                                        <ChatBubbleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body1">Create Conversation</Typography>
                                </MenuItem>
                                <MenuItem sx={{ margin: '8px', borderRadius: '4px' }} onClick={() => handleOpenDialog(selectedItem)}>
                                    <ListItemIcon>
                                        <ShareIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body1">Share</Typography>
                                </MenuItem>
                                <MenuItem disabled sx={{ margin: '8px', borderRadius: '4px' }}>
                                    <ListItemIcon>
                                        <LinkIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body1">Embed (coming soon)</Typography>
                                </MenuItem>
                                <Divider/>
                                <MenuItem onClick={() => handleDelete(selectedItem?._id)} sx={{ color: 'red', margin: '8px', borderRadius: '4px' }}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                                    </ListItemIcon>
                                    <Typography variant="body1" sx={{ color: 'red' }}>Delete</Typography>
                                </MenuItem>
                            </Menu>

                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ShareDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                title={dialogTitle}
                sharedList={sharedList}
                knowledgeBase={selectedKb}
                owner={selectedKb?.user_id === user.sub}

            />
        </>

    );
};

export default ListKnowledgeBaseBody;
