import { createSlice } from '@reduxjs/toolkit';

const initialState = { sources: [], loading: true };

const sourceSlice = createSlice({
    name: 'source',
    initialState,
    reducers: {
        updateSourcesData: (state, action) => {
            state.sources = action.payload;
            state.loading = false;
        },
        appendSourcesData: (state, action) => {
            state.sources = [...state.sources, ...action.payload];
        },
    },
});

export const { updateSourcesData, appendSourcesData } = sourceSlice.actions;
export default sourceSlice.reducer;