// pages/KnowledgeBaseDetails.js
import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
    addKnowledgeBaseSource,
    getKnowledgeBase, removeKnowledgeBaseSource,
    unShareKnowledgeBaseSource,
    updateKnowledgeBase
} from "@/api/knowledgeBase";
import Loader from "@/components/shared/Loader";
import Toolbar from "./partials/toolbar";
import { setToast } from "@/store/slices/toastSlice";
import { goToRoute } from "@/store/actions/navigationActions";
import  Overview  from "./partials/overview"
import Source from "./partials/source"

import SubjectIcon from '@mui/icons-material/Subject';
import SourceIcon from '@mui/icons-material/Source';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HistoryIcon from '@mui/icons-material/History';
import Conversation from "@/components/modules/KnowledgeBase/partials/EditKnowledgeBaseV2/partials/Conversations";
import ShareDialog from "@/components/shared/Modals/ShareModal";
import {deleteConversationApi, renameConversation} from "@/api/conversation";
import {
    clearSelectedConversationId,
    deleteConversation,
    updateConversationTitle
} from "@/store/slices/conversationSlice";
import EditKnowledgeBaseDialog from "@/components/shared/Modals/editKnowledgeBaseModal";


const KnowledgeBaseDetails = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { kbId } = useParams();
    const dispatch = useDispatch();

    const { user} = useAuth0();

    const conversations = useSelector((state) => state.conversations.conversations);
    const sources = useSelector((state) => state.sources.sources);

    const [knowledgeBaseInfo, setKnowledgeBaseInfo] = useState(null);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [filteredSources, setFilteredSources] = useState([]);
    const [availableSources, setAvailableSources] = useState([]);

    const [loading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState('overview');

    const [sharedUsers, setSharedUsers] = useState([]);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);



    useEffect(() => {
        setIsLoading(true);
        const getKbInfo = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await getKnowledgeBase(accessToken, kbId);
                setKnowledgeBaseInfo(response.data);
                // setSharedUsers(knowledgeBaseInfo?.shared_with_details)
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching knowledge base information:', error);
                setIsLoading(false);
            }
        };
        getKbInfo();
    }, [getAccessTokenSilently, kbId]);

    useEffect( () => {
        if (knowledgeBaseInfo) {
            const con = conversations.filter(
                (conversation) => conversation.knowledge_base_id === kbId
            );
            setFilteredConversations(con);
        }

    }, [conversations, knowledgeBaseInfo]);

    useEffect( () => {
        if (knowledgeBaseInfo && knowledgeBaseInfo.data_sources) {
            setFilteredSources(knowledgeBaseInfo.data_sources);

            const newAvailableSources = sources.filter((source) =>
                !knowledgeBaseInfo?.data_sources.includes(source._id) && source.source_type === knowledgeBaseInfo.source_type
            );
            setAvailableSources(newAvailableSources);
        }
    }, [sources, knowledgeBaseInfo]);

    useEffect( () => {
        setSharedUsers(knowledgeBaseInfo?.shared_with_details)
    }, [knowledgeBaseInfo]);



    const handleNewChatClick = () => {
        dispatch(goToRoute(`/kb/${kbId}/c`));
    };

    const handleShareClick = () => {
        setShareDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setShareDialogOpen(false);
    };

    const handleRemoveAccess = async (email) => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const payload = { email: email }
            const data = await unShareKnowledgeBaseSource(token, knowledgeBaseInfo._id, payload);
            setSharedUsers(data.data.shared_with_details);
            dispatch(setToast({
                message: `Removed access for ${email}`,
                toasterColor: 'success'
            }));
        } catch (error) {
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };


    const handleEdit = () => {
        setEditDialogOpen(true);
    };

    const handleSourceAdd = async (selectedSources) => {
        // console.log("Selected sources:", selectedSources);
        // setAvailableSourcesModalOpen(false);
        setIsLoading(true);
        const payload = {
            data_source_ids: selectedSources
        }
        try{
            const token = await getAccessTokenSilently();
            await addKnowledgeBaseSource(token, kbId, payload).then((response) => {
                setKnowledgeBaseInfo(response.data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                dispatch(setToast({
                    message: error.message,
                    toasterColor: 'error'
                }));
            });
        } catch(error) {
            setIsLoading(false);
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        }
    };

    const handleRenameConversation = async (values, chatId) => {
        const newTitle = values.title
        try {
            const accessToken = await getAccessTokenSilently();
            const payload = { title: newTitle };

            const response = await renameConversation(payload, accessToken, chatId);

            if (response.data) {
                // Dispatch action to update the conversation title in the store
                dispatch(updateConversationTitle({ id: chatId, title: newTitle }));
                console.log(`Renamed chat ${chatId} to ${newTitle}`);
            }
        } catch (error) {
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        } finally {
            // setIsRenaming(null);
        }
    }

    const handleDeleteConversation = async (chatToDelete) => {
        try {
            const accessToken = await getAccessTokenSilently();

            await deleteConversationApi(accessToken, chatToDelete.id);
            dispatch(deleteConversation(chatToDelete.id));
            dispatch(setToast({
                message: 'Conversation deleted successfully',
                toasterColor: 'success'
            }));

        } catch (error) {
            console.error('Failed to delete conversation:', error);
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        } finally {
        }
    };

    const handleRemoveSource = async (selectedSource) => {
        setIsLoading(true);
        const payload = {
            data_source_id: selectedSource.id
        }
        try{
            const token = await getAccessTokenSilently();
            await removeKnowledgeBaseSource(token, kbId, payload).then((response) => {
                setKnowledgeBaseInfo(response.data);
                dispatch(setToast({
                    message: 'Successfully removed the source',
                    toasterColor: 'success'
                }));

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                dispatch(setToast({
                    message: error.message,
                    toasterColor: 'error'
                }));
            });

        } catch(error) {
            setIsLoading(false);
            dispatch(setToast({
                message: error.message,
                toasterColor: 'error'
            }));
        }

    };

    const handleEditKb = async (title) => {
        try {
            setIsLoading(true);
            const payload = {
                title: title
            }
            const token = await getAccessTokenSilently();
            updateKnowledgeBase(token, kbId, payload).then(data => {
                setKnowledgeBaseInfo(data.data);
                setIsLoading(false);
                dispatch(setToast({
                    message: 'Title updated successfully',
                    toasterColor: 'success'
                }));
            }).catch(error => {
                console.error('Failed to edit knowledge base:', error);
                setIsLoading(false);
                dispatch(setToast({
                    message: error.message,
                    toasterColor: 'error'
                }));
            });

        } catch (e) {
            dispatch(setToast({
                message: e.message,
                toasterColor: 'error'
            }));
        }
    }

    if (loading) {
        return <Loader message={"Loading details"} />;
    }

    return (
        <Box height='100vh'>
            <Toolbar
                title={knowledgeBaseInfo?.title || "Knowledge Base"}
                onNewChatClick={handleNewChatClick}
                onShareClick={handleShareClick}
            />
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                    <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                        variant="fullWidth"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={<SubjectIcon />}
                            iconPosition="start"
                            label="Overview"
                            value="overview"
                            sx={{
                                textTransform: 'capitalize',
                                fontWeight: 700,
                                fontSize: '0.95rem',
                                mr: 2
                            }}
                        />
                        <Tab
                            icon={<SourceIcon />}
                            iconPosition="start"
                            label="Sources"
                            value="sources"
                            sx={{
                                textTransform: 'capitalize',
                                fontWeight: 700,
                                fontSize: '0.95rem',
                                mr: 2
                            }}
                        />
                        <Tab
                            icon={<QuestionAnswerIcon />}
                            iconPosition="start"
                            label="Conversations"
                            value="conversations"
                            sx={{
                                textTransform: 'capitalize',
                                fontWeight: 700,
                                fontSize: '0.95rem',
                                mr: 2
                            }}
                        />
                        <Tab
                            icon={<HistoryIcon />}
                            iconPosition="start"
                            label="Version History (soon)"
                            value="version"
                            disabled={true}
                            sx={{
                                textTransform: 'capitalize',
                                fontWeight: 700,
                                fontSize: '0.95rem'
                            }}
                        />
                    </TabList>
                </Box>
                <TabPanel value="overview">
                    <Overview
                        knowledgeBaseInfo={knowledgeBaseInfo}
                        onEdit={handleEdit}
                        totalConversations={filteredConversations.length}
                        totalSources={knowledgeBaseInfo?.data_sources.length}
                        user={user}
                        handleRemoveAccess={handleRemoveAccess}
                        sharedUsers={sharedUsers}
                    />

                </TabPanel>
                <TabPanel value="sources">
                    <Source
                        sources={filteredSources}
                        availableSources={availableSources}
                        handleSourceAdd={handleSourceAdd}
                        handleRemoveSource={handleRemoveSource}
                    />
                </TabPanel>
                <TabPanel value="conversations">
                    <Conversation
                        conversations={filteredConversations}
                        knowledgeBaseInfo={knowledgeBaseInfo}
                        handleDeleteConversation={handleDeleteConversation}
                        handleRename={handleRenameConversation}
                    />
                </TabPanel>

                <TabPanel value="version">Item Four</TabPanel>
            </TabContext>

            <ShareDialog
                open={shareDialogOpen}
                onClose={handleCloseDialog}
                title={knowledgeBaseInfo?.title}
                sharedList={sharedUsers}
                knowledgeBase={knowledgeBaseInfo}
                owner={knowledgeBaseInfo?.user_id === user.sub}

            />

            <EditKnowledgeBaseDialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                onSubmit={handleEditKb}
                initialTitle={knowledgeBaseInfo?.title}
                initialDescription={knowledgeBaseInfo?.description}
            />
        </Box>
    );
};

export default KnowledgeBaseDetails;
