import instance from './instances/dataSource'


export const getDataSourceList = (accessToken) => {
    return instance(accessToken).get('/v1/source');
};

export const uploadFiles = (accessToken, payload) => {
    return instance(accessToken, 'multipart/form-data').post('v1/source/file', payload)
}

export const addUrls = (accessToken, payload) => {
    return instance(accessToken, 'application/json').post('v1/source/url', payload)
}