// StepDefineKnowledgeBase.js
import React from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    InputLabel,
    Select, MenuItem
} from '@mui/material';

const categories = [
    'Marketing', 'Technical', 'C-Suite', 'Sales', 'Human Resources',
    'Operations', 'Finance', 'Customer Support', 'Legal', 'Product Management',
    'Research and Development', 'Public Relations', 'Education and Training',
    'Sustainability', 'Healthcare'
];

const StepDefineKnowledgeBase = ({ formData, onChange }) => {
    return (
        <Box sx={{ width: '70%', margin: 'auto'}}>
            <form>
                <TextField
                    fullWidth
                    label="Name"
                    value={formData.name}
                    onChange={(e) => onChange({ title: e.target.value })}
                    margin="normal"
                    marginBottom={4}
                />
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Tagline"*/}
                {/*    value={formData.tagline}*/}
                {/*    onChange={(e) => onChange({ tagline: e.target.value })}*/}
                {/*    margin="normal"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Description"*/}
                {/*    value={formData.description}*/}
                {/*    multiline*/}
                {/*    rows={4}*/}
                {/*    onChange={(e) => onChange({ description: e.target.value })}*/}
                {/*    margin="normal"*/}
                {/*/>*/}
                <FormControl fullWidth sx={{ mt: 4, minWidth: 120 }} margin="normal">
                    <InputLabel id="select-category">Category</InputLabel>
                    <Select
                        labelId="select-category"
                        label="Category"
                        value={formData.category}
                        onChange={(e) => onChange({ category: e.target.value })}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">Source Type</FormLabel>
                    <RadioGroup
                        row
                        name="sourceType"
                        value={formData.source_type}
                        onChange={(e) => onChange({ source_type: e.target.value })}
                    >
                        <FormControlLabel value="file" control={<Radio />} label="Files" />
                        <FormControlLabel value="url" control={<Radio />} label="URLs"/>
                        <FormControlLabel value="database" disabled control={<Radio />} label="Database (Coming Soon)" />

                    </RadioGroup>
                </FormControl>
            </form>
        </Box>

    );
};

export default StepDefineKnowledgeBase;
