import React, { useState } from 'react';
import { Modal, Box, Typography, CircularProgress, Avatar, Grid, Divider, IconButton, Chip, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const KnowledgeBaseInfoModal = ({ open, handleClose, knowledgeBaseInfo, loading }) => {
    const theme = useTheme();
    const [readMore, setReadMore] = useState(false);

    const handleReadMore = () => {
        setReadMore(!readMore);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Avatar
                            alt={knowledgeBaseInfo?.title}
                            src="/static/images/avatar/1.jpg" // Replace with your image path or dynamic source
                            sx={{ width: 72, height: 72, mb: 3, bgcolor: theme.palette.secondary.main }}
                        />
                        <Typography id="modal-modal-title" variant="h4" component="h3" gutterBottom mb={2}>
                            {knowledgeBaseInfo?.title || "Knowledge Base"}
                        </Typography>
                        <Typography variant="body2" sx={{
                            mb: 4,
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: readMore ? 'none' : 1, WebkitBoxOrient: 'vertical' ,
                            overflow: readMore ? 'unset' : 'auto'
                        }}>
                            {knowledgeBaseInfo?.description || "No description available."}
                        </Typography>
                        <Button onClick={handleReadMore} sx={{ mb: 4 }}>
                            {readMore ? 'Read Less' : 'Read More'}
                        </Button>
                        <Box width='100%' display="flex" justifyContent="flex-start" mt={8} mb={8}>
                            <Typography variant="h6">
                                Category
                            </Typography>
                            <Chip
                                label={knowledgeBaseInfo?.category}
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    borderRadius: '0.5rem',
                                    textTransform: 'capitalize',
                                    fontWeight: '700',
                                    ml: '1rem',
                                    padding: '1rem 1.5rem'
                                }}
                            />
                        </Box>

                        <Divider width='100%' />
                        <Grid container spacing={2} sx={{ width: '100%', mb: 8, mt: 8, justifyContent: 'center' }}>
                            <Grid item xs={5}>
                                <Typography variant="body2" align="center" sx={{ textTransform: 'capitalize' }}>
                                    <strong>{knowledgeBaseInfo?.source_type || "N/A"}</strong>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    Source Type
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item xs={5}>
                                <Typography variant="body2" align="center">
                                    <strong>{knowledgeBaseInfo?.conversations || "0"}</strong>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    Conversations
                                </Typography>
                            </Grid>
                        </Grid>

                        {knowledgeBaseInfo?.prompts && knowledgeBaseInfo?.prompts.length > 0 && (
                            <>
                                <Divider width='100%' />
                                <Typography variant="h5" component="h2" align="left" sx={{ mb: 2, mt: 4 }}>
                                    Conversation Starters
                                </Typography>
                                <Grid container spacing={2}>
                                    {knowledgeBaseInfo?.prompts?.slice(0, 4).map((prompt, index) => (
                                        <Grid item xs={6} key={index}>
                                            <Box
                                                sx={{
                                                    border: `1px solid ${theme.palette.divider}`,
                                                    borderRadius: 2,
                                                    p: 2,
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {prompt}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}

                    </>
                )}
            </Box>
        </Modal>
    );
};

export default KnowledgeBaseInfoModal;
