// import {deleteAuthTokenFromLocalStorage} from "@/utils/auth-token-utils";

const ROUTE_PATHS = {
    LOGIN: '/login',
    KNOWLEDGE: '/',
    KNOWLEDGE_CREATE:'/knowledge/create',
    KNOWLEDGE_EDIT: '/knowledge/edit/:knowledgeId',
    KNOWLEDGE_DETAILS: '/kb/:kbId/d',
    DATA_SOURCES: '/sources',
    CONVERSATION: '/kb/:kbId/c/',
    CONVERSATION_VIEW: '/kb/:kbId/c/:conversationId',
    NOT_FOUND: '/404',
    NOT_AUTHORISED: '/403',
}

// export const isLoginPath = (location) => {
//     return (
//         Object.values([ROUTE_PATHS.LOGIN]).findIndex(
//             (route) => location && location.pathname && location.pathname.includes(route),
//         ) !== -1
//     );
// };
//
// export const handleLogout = () => {
//     deleteAuthTokenFromLocalStorage();
//     window.location.reload();
// };

export default ROUTE_PATHS;