import { updateSourcesData } from '@/store/slices/sourceSlice';
import { getDataSourceList } from '@/api/dataSource';
import { goToRoute } from './navigationActions';
import { setToast } from '@/store/slices/toastSlice';

export const SourceDataGetAndStore = (accessToken) => {
    return (dispatch) => {
        accessToken
            ? getDataSourceList(accessToken)
                .then((res) => {
                    if (res.data) {
                        dispatch(updateSourcesData(res.data));
                    }
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(
                        setToast({
                            message: e.message,
                            toasterColor: 'error',
                        })
                    );
                })
            : dispatch(goToRoute('/login'));
    };
};