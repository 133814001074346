// ChatHistory.js
import React, { useState } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, MenuItem, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import getIcon from '@/utils/get-icon';
import { goToRoute } from '@/store/actions/navigationActions';
import {
    setSelectedConversationId,
    setConversationCreated,
    updateConversationTitle,
    clearSelectedConversationId, deleteConversation
} from '@/store/slices/conversationSlice';
import StyledMenu from '@/components/shared/StyledMenu';
import ConfirmationDialog from "@/components/shared/ConfirmModal";
import {useAuth0} from "@auth0/auth0-react";
import {deleteConversationApi, renameConversation} from "@/api/conversation"; // Adjust the import path as necessary


export default function ChatHistory({ chatHistory, open }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const selectedConversationId = useSelector((state) => state.conversations.selectedConversationId);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeChatId, setActiveChatId] = useState(null);
    const [hoveredChatId, setHoveredChatId] = useState(null);
    const [isRenaming, setIsRenaming] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    const handleMenuOpen = (event, chatId) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
        setActiveChatId(chatId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
        setActiveChatId(null);
        setHoveredChatId(null);
    };

    const handleRenameClick = (chatId, currentTitle) => {
        console.log(`handleRenameClick: ${chatId}, ${currentTitle}`)
        setIsRenaming(chatId);
        setNewTitle(currentTitle);
        handleMenuClose();
    };

    const handleRenameChange = (e) => {
        setNewTitle(e.target.value);
    };

    const handleRenameConfirm = async (chatId) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const payload = { title: newTitle };

            const response = await renameConversation(payload, accessToken, chatId);

            if (response.data) {
                // Dispatch action to update the conversation title in the store
                dispatch(updateConversationTitle({ id: chatId, title: newTitle }));
                console.log(`Renamed chat ${chatId} to ${newTitle}`);
            }
        } catch (error) {
            console.error('Failed to rename conversation:', error);
        } finally {
            setIsRenaming(null);
        }
    };

    const handleDelete = (chat) => {
        // Handle delete action
        console.log(`Deleting chat ${chat}`);
        setChatToDelete(chat);
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = async () => {
        // Dispatch action to delete chat
        try {
            const accessToken = await getAccessTokenSilently();

            await deleteConversationApi(accessToken, chatToDelete._id);
            dispatch(deleteConversation(chatToDelete._id));
            if (chatToDelete._id === selectedConversationId) {
                // Clear the selected conversation ID
                dispatch(clearSelectedConversationId());
                dispatch(goToRoute(`/`));
            }
        } catch (error) {
            console.error('Failed to delete conversation:', error);
        } finally {
            setDeleteDialogOpen(false);
            setChatToDelete(null);
        }

    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setChatToDelete(null);
    };

    const handleClick = (cId, kbId) => {
        const url = `/kb/${kbId}/c/${cId}`;
        dispatch(goToRoute(url));
        dispatch(setSelectedConversationId(cId));
        dispatch(setConversationCreated(false));
    };

    const handleMouseEnter = (chatId) => {
        setHoveredChatId(chatId);
    };

    const handleMouseLeave = () => {
        setHoveredChatId(null);
    };

    return (
        <Box sx={{ overflowY: 'auto' }}>
            <List>
                {chatHistory.map((chat, index) => (
                    <ListItem
                        key={index}
                        disablePadding
                        onMouseEnter={() => handleMouseEnter(chat._id)}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            borderRadius: '1rem',
                            width: open ? '90%' : '85%',
                            margin: theme.spacing(1),
                            paddingLeft: '0.7rem',
                            paddingRight: '0.7rem',
                            '&:hover': {
                                backgroundColor: isRenaming === chat._id ? 'defaultBackgroundColor' : 'white',
                            },
                            backgroundColor: selectedConversationId === chat._id ? theme.palette.secondary.light : 'defaultBackgroundColor'
                        }}
                        secondaryAction={
                            !isRenaming && open && (hoveredChatId === chat._id || selectedConversationId === chat._id) && (
                                <>
                                    <IconButton edge="end" onClick={(event) => handleMenuOpen(event, chat._id)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <StyledMenu
                                        anchorEl={anchorEl}
                                        open={menuOpen && activeChatId === chat._id}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => handleRenameClick(chat._id, chat.title)} disableRipple sx={{ margin: '8px', borderRadius: '8px' }}>
                                            {getIcon('edit', 'inherit',)}
                                            Rename
                                        </MenuItem>
                                        <MenuItem onClick={() => handleDelete(chat)} disableRipple sx={{ margin: '8px', borderRadius: '8px' }}>
                                            {getIcon('delete', 'inherit', 'small')}
                                            Delete
                                        </MenuItem>
                                    </StyledMenu>
                                </>
                            )
                        }
                    >
                        {isRenaming === chat._id ? (
                            <TextField
                                value={newTitle}
                                onChange={handleRenameChange}
                                onBlur={() => handleRenameConfirm(chat._id)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleRenameConfirm(chat._id);
                                    }
                                }}
                                size="small"
                                autoFocus
                                fullWidth
                            />
                        ) : (
                            <ListItemButton
                                onClick={() => handleClick(chat._id, chat.knowledge_base_id)}
                                sx={{
                                    pointerEvents: isRenaming === chat._id ? 'none' : 'auto',
                                    '&:hover': {
                                        backgroundColor: isRenaming === chat._id ? 'transparent' : 'defaultBackgroundColor',
                                    },
                                    paddingLeft: '8px',

                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: 'black',
                                        minWidth: '0px'

                                    }}
                                >
                                    {getIcon('chat', 'black', 'small')}
                                </ListItemIcon>
                                <ListItemText
                                    primary={chat.title}
                                    primaryTypographyProps={{
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                    sx={{
                                        paddingLeft: '1rem'
                                    }}
                                />
                            </ListItemButton>
                        )}
                    </ListItem>
                ))}
            </List>
            <ConfirmationDialog
                open={deleteDialogOpen}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                title="Confirm Delete"
                description="Are you sure you want to delete this Conversation?"
            />



        </Box>
    );
}

