import React from 'react'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MobileOffIcon from '@mui/icons-material/MobileOff'
import CloudCircleIcon from '@mui/icons-material/CloudCircle';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StorageIcon from '@mui/icons-material/Storage';
import ChatIcon from '@mui/icons-material/Chat';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


export default function getIcon(
    iconType,
    color = 'inherit',
    size = 'medium',
    className = '',
    weight = 700
) {
    switch (iconType) {
        case 'dashboard':
            return (
                <DashboardIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )

        case 'user-management':
            return (
                <ManageAccountsIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )

        case 'conversations':
            return (
                <QuestionAnswerIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'thumbs_up':
            return (
                <ThumbUpIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'thumbs_down':
            return (
                <ThumbDownIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'done':
            return (
                <DoneOutlinedIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'clear':
            return (
                <ClearIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'warning':
            return (
                <ErrorOutlineOutlinedIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'notSupportedView':
            return (
                <MobileOffIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )
        case 'audienceUpload':
            return (
                <CloudCircleIcon
                    color={color}
                    className={className}
                    fontSize={size}
                />
            )

        case 'knowledge':
            return (
                <TipsAndUpdatesRoundedIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'source':
            return (
                <StorageIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'leftArrow':
            return (
                <ChevronLeftIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'rightArrow':
            return (
                <ChevronRightIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'questionAnswerRound':
            return (
                <QuestionAnswerRoundedIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'person':
            return (
                <PersonIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )
        case 'exit':
            return (
                <ExitToAppIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'chat':
            return (
                <ChatIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'edit':
            return (
                <EditIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'delete':
            return (
                <DeleteIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        case 'add':
            return (
                <AddCircleOutlineIcon
                    color={color}
                    className={className}
                    fontSize={size}
                    fontWeight={weight}
                />
            )

        default:
            console.log(`Warning: icon for ${iconType} not found!`)
            return <div>{iconType}</div>
    }
}
