import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    conversations: [],
    loading: true,
    selectedConversationId: null,
    conversationCreated: false
};

const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        updateConversationsData: (state, action) => {
            state.conversations = action.payload;
            state.loading = false;
        },
        appendConversationsData: (state, action) => {
            state.conversations = [action.payload, ...state.conversations];
        },
        setSelectedConversationId: (state, action) => {
            state.selectedConversationId = action.payload;
        },
        clearSelectedConversationId: (state) => {
            state.selectedConversationId = null;
        },
        setConversationCreated: (state, action) => {
            state.conversationCreated = action.payload
        },
        updateConversationTitle: (state, action) => {
            const { id, title } = action.payload;
            const conversation = state.conversations.find(convo => convo._id === id);
            if (conversation) {
                conversation.title = title;
            }
        },
        deleteConversation: (state, action) => {
            state.conversations = state.conversations.filter(convo => convo._id !== action.payload);
        }
    },
});

export const {
    updateConversationsData,
    appendConversationsData,
    setSelectedConversationId,
    clearSelectedConversationId,
    setConversationCreated,
    updateConversationTitle,
    deleteConversation
} = conversationSlice.actions;
export default conversationSlice.reducer;