import { createTheme } from '@mui/material/styles';

const themeOptions = {
    palette: {
        common: {
            black: '#000000',
            white: '#FFFFFF',
        },
        primary: {
            main: '#360efa',
            light: '#f2f0ff',
            dark: '#2704d6',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#ff5a8b',
            light: '#fff0f4',
            dark: '#ff145a',
            contrastText: '#000000',
        },
        background: {
            default: '#FFFBF7',
            paper: '#FFFFFF',
        },
        info: {
            main: '#379ae6',
            light: '#f1f8fd',
            dark: '#197dca',
            contrastText: '#000000',
        },
        warning: {
            main: '#efb034',
            light: '#fef9ee',
            dark: '#d29211',
            contrastText: '#000000',
        },
        error: {
            main: '#de3b40',
            light: '#fdf2f2',
            dark: '#c12126',
            contrastText: '#FFFFFF',
        },
        success: {
            main: '#1dd75b',
            light: '#eefdf3',
            dark: '#17a948',
            contrastText: '#000000',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '3rem',
            lineHeight: '4.25rem',
        },
        h2: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '2.5rem',
            lineHeight: '3.5rem',
        },
        h3: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '2rem',
            lineHeight: '3rem',
        },
        h4: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '1.5rem',
            lineHeight: '2.25rem',
        },
        h5: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
        },
        h6: {
            fontFamily: 'Poppins, "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
        },
        body1: {
            fontSize: '1rem',
            lineHeight: '1.625rem',
        },
        body2: {
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
        },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    shape: {
        borderRadius: 4,
    },
    // shadows: [
    //     'none',
    //     '0px 0px 1px rgba(23, 26, 31, 0.07), 0px 0px 2px rgba(23, 26, 31, 0.12)',
    //     '0px 2px 5px rgba(23, 26, 31, 0.09), 0px 0px 2px rgba(23, 26, 31, 0.12)',
    //     '0px 4px 9px rgba(23, 26, 31, 0.11), 0px 0px 2px rgba(23, 26, 31, 0.12)',
    //     '0px 8px 17px rgba(23, 26, 31, 0.15), 0px 0px 2px rgba(23, 26, 31, 0.12)',
    //     '0px 17px 35px rgba(23, 26, 31, 0.24), 0px 0px 2px rgba(23, 26, 31, 0.12)',
    //     // ... (add more shadow values as needed)
    // ],
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0.25rem', // Corresponds to 'm' in your borderRadius
                    textTransform: 'capitalize',
                    fontWeight: 700,
                    paddingLeft: '3rem',
                    paddingRight: '3rem',
                },
                containedPrimary: {
                    background: 'linear-gradient(to right, #360efa 0%, #8E54E9  100%)',
                    // background: 'linear-gradient(45deg, #360efa 30%, #6547fb 90%)',
                    '&:hover': {
                        background: 'linear-gradient(to right, #360efa 0%, #333399  51%, #ff5a8b  100%)'
                        // background: 'linear-gradient(to right, #2704d6 80%, #ff5a8b  100%)'
                        // background: 'linear-gradient(45deg, #2704d6 30%, #8E54E9 90%)',
                    },
                    color: '#FFF',
                    paddingLeft: '3rem',
                    paddingRight: '3rem'
                },
                containedSecondary: {
                    backgroundColor: '#FFFFFF',
                    color: '#000',
                    '&:hover': {
                        backgroundColor: '#F8F8F8',
                    },
                },
                outlined: {
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    },
                },
                outlinedPrimary: {
                    color: '#360efa',
                    borderColor: '#360efa',
                    '&:hover': {
                        backgroundColor: 'rgba(54, 14, 250, 0.04)',
                    },
                },
                outlinedSecondary: {
                    color: '#360efa',
                    borderColor: '#360efa',
                    '&:hover': {
                        backgroundColor: 'rgba(54, 14, 250, 0.04)',
                        borderColor: '#360efa',
                    },
                },
            },
        },
    },
};

const theme = createTheme(themeOptions);

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#F44336',
//             light: '#FCB9B2'
//         },
//         secondary: {
//             main: '#BDBDBD',
//             light: '#FFFFFF',
//             dark: '#616161', // Darker yellow shade
//         },
//         background: {
//             default: '#FFFFFF',
//             paper: '#FFFFFF',
//             dark: '#BDBDBD',
//             light: '#E0E0E0',
//             selected: '#616161'
//         },
//         common: {
//             black: '#000000',
//             white: '#FFFFFF',
//         },
//         text: {
//             primary: '#1A1A1A', // Dark text color
//             secondary: '#5F6368', // Light text color
//         },
//         info: {
//             main: '#FDF6D8', // Light yellow shade (same as secondary.main)
//         },
//         warning: {
//             main: '#FFC107', // Orange shade
//         },
//         error: {
//             main: '#E53935', // Red shade
//         },
//         success: {
//             main: '#4CAF50', // Green shade
//         },
//     },
//     // typography: {
//     //     h1: {
//     //         fontSize: '1.25rem', // equivalent to 20px
//     //         fontWeight: 700,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '1.5rem', // scales up to 24px on larger screens
//     //         },
//     //         '@media (min-width:900px)': {
//     //             fontSize: '2rem', // scales up to 32px on larger screens
//     //         },
//     //     },
//     //     h2: {
//     //         fontSize: '1rem', // equivalent to 16px
//     //         fontWeight: 600,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '1.125rem', // scales up to 18px on larger screens
//     //         },
//     //         '@media (min-width:900px)': {
//     //             fontSize: '1.25rem', // scales up to 20px on larger screens
//     //         },
//     //     },
//     //     h3: {
//     //         fontSize: '0.85rem', // equivalent to 16px
//     //         fontWeight: 600,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '1rem', // scales up to 18px on larger screens
//     //         },
//     //         '@media (min-width:900px)': {
//     //             fontSize: '1.125rem', // scales up to 20px on larger screens
//     //         },
//     //     },
//     //     body1: {
//     //         fontSize: '0.85rem', // equivalent to 13.6px
//     //         fontWeight: 400,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '1rem', // scales up to 15.2px on larger screens
//     //         },
//     //     },
//     //     // Define other typography variants as needed
//     //     body2: {
//     //         fontSize: '0.75rem', // equivalent to 12px
//     //         fontWeight: 400,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '0.875rem', // scales up to 14px on larger screens
//     //         },
//     //     },
//     //     subtitle1: {
//     //         fontSize: '0.85rem', // equivalent to 14px
//     //         fontWeight: 500,
//     //         '@media (min-width:600px)': {
//     //             fontSize: '0.9rem', // scales up to 16px on larger screens
//     //         },
//     //     },
//     //     subtitle2: {
//     //         fontSize: '0.75rem', // equivalent to 12px
//     //         fontWeight: 500,
//     //     },
//     //     button: {
//     //         fontSize: '0.875rem', // equivalent to 14px
//     //         fontWeight: 700,
//     //         textTransform: 'inherit',
//     //     },
//     //     caption: {
//     //         fontSize: '0.75rem', // equivalent to 12px
//     //         fontWeight: 400,
//     //     },
//     //     overline: {
//     //         fontSize: '0.625rem', // equivalent to 10px
//     //         fontWeight: 400,
//     //         // textTransform: 'uppercase',
//     //     },
//     //     // Feel free to add or adjust as needed for other variants
//     // },
//     // shape: {
//     //     borderRadius: '8px'
//     // },
//     components: {
//         // Name of the component
//         MuiDrawer: {
//             styleOverrides: {
//                 paper: {
//                     width: drawerWidth,
//                     flexShrink: 0,
//                     elevation: 3,
//                     whiteSpace: 'nowrap',
//                     boxSizing: 'border-box',
//                     overflowX: 'hidden',
//                     backgroundColor: drawerBackground,
//                     '& .MuiDrawer-paper': {
//                         width: drawerWidth,
//                         boxSizing: 'border-box',
//                         elevation: 3,
//                         backgroundColor: drawerBackground,
//                     },
//                     transition: (theme) =>
//                         theme.transitions.create('width', {
//                             easing: theme.transitions.easing.sharp,
//                             duration: theme.transitions.duration.enteringScreen,
//                         }),
//                 },
//             },
//         },
//         MuiIconButton: {
//             styleOverrides: {
//                 root: {
//                     // set the color you want for the icon
//                     color: '#1A1A1A', // replace with your desired color
//                 },
//             },
//         },
//     },
//     mixins: {
//         toolbar: {
//             minHeight: 56,
//             '@media (min-width:0px) and (orientation: landscape)': {
//                 minHeight: 64,
//             },
//             '@media (min-width:600px)': {
//                 minHeight: 64,
//             },
//         },
//     },
// });

export default theme;