import React, { useState } from 'react';
import PropTypes from "prop-types";

import ReactMarkdown from 'react-markdown';

import {
    Box,
    Typography,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Link,
    Tooltip, Button
} from '@mui/material';

import DocumentModal from '@/components/shared/Modals/DocumentViewerModal'

//styles
import {useTheme} from "@mui/material/styles";
import "./styles.css";
import GrainIcon from '@mui/icons-material/Grain';


const MessageCard = ({ index, msg, userName }) => {
    const theme = useTheme();
    // const [expanded, setExpanded] = useState(false);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [showSources, setShowSources] = useState(false);

    // const handleAccordionChange = () => {
    //     setExpanded(!expanded);
    // };
    const handleFileClick = (fileUrl) => {
        const url = `uploads/${fileUrl}`
        setDocumentUrl(url);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setDocumentUrl(null);
    };

    // return (
    //     <Box sx={{
    //         p: 4,
    //         display: 'flex',
    //         flexDirection: 'column',
    //         mt: 8,
    //         elevation: msg.isUser ? '3' : '0',
    //         background: msg.isUser ? theme.palette.secondary.light : 'inherit',
    //         borderRadius: '1rem',
    //     }}>
    //         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
    //             <Avatar  sx={{
    //                 width: 32,
    //                 height: 32,
    //                 fontSize: '14px',
    //                 backgroundColor: msg.isUser ? "secondary.main" : "primary.main"
    //             }}>
    //                 {msg.isUser ? userName[0] : "C"}
    //             </Avatar>
    //             <Box variant="body1" sx={{ ml: 4 }}>
    //                 <ReactMarkdown className="reactMarkDown">
    //                     {msg.text}
    //                 </ReactMarkdown>
    //             </Box>
    //         </Box>
    //         {msg.citations && msg.citations.length > 0 && (
    //             <Accordion
    //                 square={true}
    //                 expanded={expanded}
    //                 onChange={handleAccordionChange}
    //                 disableGutters
    //                 sx={{
    //                     borderRadius: '1rem',
    //                     mt: 2,
    //                     pl:2,
    //                     pr: 2,
    //                     '&:before': {
    //                         display: 'none',
    //                     }
    //             }}>
    //                 <AccordionSummary
    //                     expandIcon={<ExpandMoreIcon />}
    //                     aria-controls="panel1a-content"
    //                     id="panel1a-header"
    //                 >
    //                     <Typography variant="caption" component="div" sx={{ fontWeight: 'bold' }}>
    //                         Sources
    //                     </Typography>
    //                 </AccordionSummary>
    //                 <AccordionDetails>
    //                     <List dense>
    //                         {msg.citations.map((citation, idx) => (
    //                             <ListItem key={idx} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
    //                                 <ListItemText
    //                                     primary={
    //                                         <Typography variant="body2">
    //                                             {citation.quote}
    //                                         </Typography>
    //                                     }
    //                                     secondary={
    //                                         citation.page_url && citation.page_title ? (
    //                                             <Typography variant="caption" color="text.primary" sx={{ fontWeight: '600'}}>
    //                                                 <strong>Source:</strong>
    //                                                 <Link href={citation.page_url} target="_blank" rel="noopener noreferrer">
    //                                                     {citation.page_title}
    //                                                 </Link>
    //                                             </Typography>
    //                                         ) : (
    //                                             <Typography variant="caption" color="text.primary" sx={{ fontWeight: '600'}}>
    //                                                 <strong>Source:</strong>
    //                                                 <Link href="#" onClick={() => handleFileClick(citation.file_name)}>
    //                                                     {citation.file_name}
    //                                                 </Link> <br />
    //                                                 <strong>Page:</strong> {citation.page_number}
    //                                             </Typography>
    //                                         )
    //                                     }
    //                                 />
    //                             </ListItem>
    //                         ))}
    //                     </List>
    //                 </AccordionDetails>
    //             </Accordion>
    //         )}
    //         <DocumentModal
    //             isOpen={modalOpen}
    //             onClose={handleCloseModal}
    //             documentUrl={documentUrl}
    //         />
    //     </Box>
    // );


    return (
        <Box sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            mt: 8,
            elevation: msg.isUser ? '3' : '0',
            background: msg.isUser ? theme.palette.secondary.light : 'inherit',
            borderRadius: '1rem',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Avatar sx={{
                    width: 32,
                    height: 32,
                    fontSize: '14px',
                    backgroundColor: msg.isUser ? "secondary.main" : "primary.main"
                }}>
                    {msg.isUser ? userName[0] : "C"}
                </Avatar>
                <Box variant="body1" sx={{ ml: 4, flex: 1 }}>
                    <ReactMarkdown className="reactMarkDown">
                        {msg.text}
                    </ReactMarkdown>
                </Box>
                {msg.citations && msg.citations.length > 0 && (
                    <Tooltip title={!showSources ? "Show Sources" : "Hide Sources"}>
                        <Button
                            variant="text"
                            sx={{
                                background: 'linear-gradient(to right, #360efa, #8E54E9)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                            startIcon={<GrainIcon />}
                            onClick={() => setShowSources(!showSources)}
                        >
                            Sources
                        </Button>
                    </Tooltip>
                )}
            </Box>
            {showSources && (
                <Accordion
                    square={true}
                    expanded={true}
                    // onChange={handleAccordionChange}
                    disableGutters
                    sx={{
                        borderRadius: '1rem',
                        mt: 2,
                        pl: 2,
                        pr: 2,
                        '&:before': {
                            display: 'none',
                        }
                    }}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="caption" component="div" sx={{ fontWeight: 'bold' }}>
                            Sources
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List dense>
                            {msg.citations.map((citation, idx) => (
                                <ListItem key={idx} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                {citation.quote}
                                            </Typography>
                                        }
                                        secondary={
                                            citation.page_url && citation.page_title ? (
                                                <Typography variant="caption" color="text.primary" sx={{ fontWeight: '600' }}>
                                                    <strong>Source:</strong>
                                                    <Link href={citation.page_url} target="_blank" rel="noopener noreferrer">
                                                        {citation.page_title}
                                                    </Link>
                                                </Typography>
                                            ) : (
                                                <Typography variant="caption" color="text.primary" sx={{ fontWeight: '600' }}>
                                                    <strong>Source:</strong>
                                                    <Link href="#" onClick={() => handleFileClick(citation.file_name)}>
                                                        {citation.file_name}
                                                    </Link> <br />
                                                    <strong>Page:</strong> {citation.page_number}
                                                </Typography>
                                            )
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}
            <DocumentModal
                isOpen={modalOpen}
                onClose={handleCloseModal}
                documentUrl={documentUrl}
            />
        </Box>
    );
};


MessageCard.propTypes = {
    index: PropTypes.number.isRequired,
    msg: PropTypes.object.isRequired,
    userName: PropTypes.string.isRequired,
}

export default MessageCard;
