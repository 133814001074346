
import React from "react";

import routePaths from "@/utils/route-paths"
import  KnowledgeBase  from "@/components/modules/KnowledgeBase";
import CreateKnowledgeBase from "@/components/modules/KnowledgeBase/partials/CreateKnowledgeBase";
import ListDataSources from "@/components/modules/DataSource/ListDataSource";
import Conversation from "@/components/modules/Conversation";
import KnowledgeBaseDetails from "@/components/modules/KnowledgeBase/partials/EditKnowledgeBaseV2";

const platformRoutes = [
    {
        routePath: routePaths.KNOWLEDGE,
        component: <KnowledgeBase />
    },
    {
        routePath: routePaths.KNOWLEDGE_CREATE,
        component: <CreateKnowledgeBase />
    },

    {
        routePath: routePaths.KNOWLEDGE_DETAILS,
        component: <KnowledgeBaseDetails />
    },
    {
        routePath: routePaths.DATA_SOURCES,
        component: <ListDataSources/>
    },
    {
        routePath: routePaths.CONVERSATION,
        component: <Conversation/>
    },
    {
        routePath: routePaths.CONVERSATION_VIEW,
        component: <Conversation/>
    }
]

export default platformRoutes;