// components/Overview/Overview.js
import React from 'react';
import {Grid, Paper} from '@mui/material';
import Header from './Header';
import StatsCards from './StatsCard';
import SharingDetails from './SharingDetails';
import SourceIcon from '@mui/icons-material/Source';
import {
    Chat as ConversationIcon,
    Update as UpdateIcon,
    Person as PersonIcon
} from '@mui/icons-material';

const Overview = ({ knowledgeBaseInfo, onEdit, totalConversations, totalSources, user, handleRemoveAccess, sharedUsers }) => {
    const stats = [
        { icon: <SourceIcon />, title: 'Total Sources', value: totalSources },
        { icon: <ConversationIcon />, title: 'Total Conversations', value: totalConversations },
        { icon: <UpdateIcon />,  title: 'Last Updated At', value: new Date(knowledgeBaseInfo?.updated_at).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            })
        },
        {
            value: knowledgeBaseInfo?.updated_by_details.name,
            email: knowledgeBaseInfo?.updated_by_details.email,
            pic: knowledgeBaseInfo?.updated_by_details.picture,
            title: 'Last Updated By',
            icon: <PersonIcon />,
            type: 'lastUpdatedBy',
        },
        {
            value: knowledgeBaseInfo?.created_by_details.name,
            email: knowledgeBaseInfo?.created_by_details.email,
            pic: knowledgeBaseInfo?.created_by_details.picture,
            title: 'Owner',
            icon: <PersonIcon />,
            type: 'owner',
        },
    ];

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={7}>
                <Paper sx={{ padding: '1rem', borderRadius: 4, elevation: 3}}>
                    <Header
                        name={knowledgeBaseInfo?.title}
                        description={knowledgeBaseInfo?.description}
                        onEdit={onEdit}
                        status={knowledgeBaseInfo?.status}
                        source_type={knowledgeBaseInfo?.source_type}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Paper sx={{ padding: '1rem', borderRadius: 4, elevation: 3}}>
                    <SharingDetails
                        sharedUsers={sharedUsers}
                        owner={knowledgeBaseInfo?.user_id === user.sub}
                        handleRemoveAccess={handleRemoveAccess}
                    />
                </Paper>
            </Grid>
            <StatsCards stats={stats} />
        </Grid>
    );
};

export default Overview;
