// NavigationMenu.js
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Box, CssBaseline } from '@mui/material';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import MiniDrawer from '@/components/shared/TemporaryDrawer';
import ResponsiveDrawer from '@/components/shared/TemporaryDrawer/ResponsiveDrawer';

import getNavigationDrawerProps from '@/props-constants/NavigationDrawerProps';
import { clearSelectedConversationId } from '@/store/slices/conversationSlice';



const drawerWidth = 300;

const NavigationMenu = ({ children }) => {
    const conversations = useSelector((state) => state.conversations.conversations);

    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };


    const shouldHighlightNavigationAction = (action) => {
        if (action.goto === location.pathname) {
            return true;
        } else if (action.matchPath && action.matchPath === location.pathname.split('/')[1]) {
            return true;
        } else if (action.path === location.pathname) return true;
        return false;
    };

    const onClickAction = (action) => {
        action.goto && action.goto();
        dispatch(clearSelectedConversationId())
    };

    const NavigationDrawerProps = getNavigationDrawerProps(dispatch);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    display:{ 'sm': 'none'}
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Conversatio
                    </Typography>
                </Toolbar>
            </AppBar>
            <ResponsiveDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                handleDrawerClose={handleDrawerClose}
                handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                conversations={conversations}
                actionsList={NavigationDrawerProps.actionsList}
                shouldHighlight={shouldHighlightNavigationAction}
                onClickAction={onClickAction}
            />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: '#FFFBF7'
                }}
            >
                <Toolbar sx={{
                    display: {'sm': 'none'}
                }}/>
                {children}
            </Box>
        </Box>
    );
};


export default NavigationMenu;

