// KnowledgeBaseList.js
import React, { useState } from 'react';
import MainPageLayout from "@/components/layout/MainPageLayout";
import ListKnowledgeBaseHeader from './header'
import ListKnowledgeBaseBody from './body'

const KnowledgeBaseList = ({
                               items,
                               handleAddKnowledgeClick,
                               handleDeleteClick,
                               handleEdit,
                               handleCardClick,

}) => {
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (newSearchText) => {
        setSearchText(newSearchText);
    };

    const filteredItems = items.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    console.log(`Search filteredItems: ${filteredItems}`)

    return (
        <MainPageLayout
            body={
                <ListKnowledgeBaseBody
                    items={filteredItems}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEdit}
                    handleCardClick={handleCardClick}
                />
            }
            header={<ListKnowledgeBaseHeader
                onSearchChange={handleSearchChange}
                searchValue={searchText}
                handleAddKnowledgeClick={handleAddKnowledgeClick} />}>

        </MainPageLayout>
    );
};

export default KnowledgeBaseList;
