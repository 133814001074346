import React from 'react';

import NavigationMenu from "@/components/shared/NavigationMenu"


export default function DefaultLayout({ children }) {
    return (
        <NavigationMenu>
            {children}
        </NavigationMenu>
    )
}