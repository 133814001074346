 import instance from './instances/knowledgeBase'




export function getKnowledgeBases(accessToken) {
    return instance(accessToken).get('');
}

 export const getDatabasesSources = () => {
     return new Promise((resolve, reject) => {
         setTimeout(() => {
             resolve([
                 { id: 1, name: "Database One", type: "SQL", createdAt: Date.now() },
                 { id: 2, name: "Database Two", type: "NoSQL", createdAt: Date.now() }
             ]);
         }, 1000); // Simulate network delay
     });
 };


export const addKnowledgeBase = (accessToken, payload) => {
    return instance(accessToken).post('', payload)
};

export const deleteKnowledgeBase = (accessToken, kbId) => {
    return instance(accessToken).delete(`/${kbId}`)
}

export const getKnowledgeBase = (accessToken, kbId) => {
    return instance(accessToken).get(`/${kbId}`)
}

export const updateKnowledgeBase = (accessToken, kbId, payload) => {
    return instance(accessToken).put(`/${kbId}`, payload)
}

export const addKnowledgeBaseSource = (accessToken, kbId, payload) => {
    return instance(accessToken).put(`/${kbId}/source/add`, payload)
}

export const shareKnowledgeBaseSource = (accessToken, kbId, payload) => {
    return instance(accessToken).post(`/${kbId}/share`, payload)
}

export const unShareKnowledgeBaseSource = (accessToken, kbId, payload) => {
 return instance(accessToken).post(`/${kbId}/remove`, payload)
}

export const removeKnowledgeBaseSource = (accessToken, kbId, payload) => {
    return instance(accessToken).put(`/${kbId}/source/remove`, payload)
}

