// components/Toolbar.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ShareIcon from '@mui/icons-material/Share';


const Toolbar = ({ title, onNewChatClick, onShareClick }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
            <Typography variant="h3">{title}</Typography>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNewChatClick}
                    startIcon={<AutoAwesomeIcon/>}
                    sx={{ mr: 4 }}
                >
                    New Chat
                </Button>
                <Button variant="contained" color="secondary" onClick={onShareClick} startIcon={<ShareIcon/>}>
                    Share
                </Button>
            </Box>
        </Box>
    );
};

export default Toolbar;
