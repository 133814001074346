import * as React from 'react';
import {Button, Box, Switch, FormControlLabel, Typography} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledMenu from '@/components/shared/StyledMenu';


export default function ConversationHeader({
                                               knowledgeBaseName,
                                               knowledgeBaseSourceType,
                                               handleNewChatClicked,
                                               handleAboutClicked,
                                               onPlotSwitchChange,
                                               onCitationSwitchChange
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAboutMenuClick = () => {
        handleClose();
        handleAboutClicked();
    };

    const handleNewChatMenuClick = () => {
        handleClose();
        handleNewChatClicked();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                component="h2"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {knowledgeBaseName ? knowledgeBaseName : 'Knowledge Base'}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleNewChatMenuClick} disableRipple sx={{ margin: '8px', borderRadius: '8px' }}>
                    <ChatOutlinedIcon />
                    New Chat
                </MenuItem>
                <MenuItem onClick={handleAboutMenuClick} disableRipple sx={{ margin: '8px', borderRadius: '8px' }}>
                    <InfoOutlinedIcon />
                    About
                </MenuItem>
            </StyledMenu>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {knowledgeBaseSourceType === 'database' ? (
                    <FormControlLabel
                        control={<Switch onChange={(e) => onPlotSwitchChange(e.target.checked)} />}
                        label={<Typography variant="body" sx={{fontWeight: '700'}}>Plot Mode</Typography>}
                        sx={{ ml: 2 }}
                    />
                ) : (knowledgeBaseSourceType === 'file' || knowledgeBaseSourceType === 'url') && (
                    <FormControlLabel
                        control={<Switch  onChange={(e) => onCitationSwitchChange(e.target.checked)} size="small"/>}
                        label={<Typography variant="body" sx={{fontWeight: '700', paddingRight: '8px'}}>Get Sources</Typography>}
                        labelPlacement="start"
                    />
                )}
            </Box>
        </Box>
    );
}
