// UserProfile.js
import React from 'react';

import {List, ListItemButton, ListItemIcon, ListItemText, Avatar, Link} from '@mui/material';

import getIcon from "@/utils/get-icon";
import {useAuth0} from "@auth0/auth0-react";

export default function UserActions() {

    const { user, logout } = useAuth0();

    if (!user) {
        return null;
    }

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };
    return (
        <List>
            <ListItemButton sx={{ ...itemStyle, }}>
                <ListItemIcon>
                    <Avatar sx={{ ...avatarStyle }} src={user.picture} alt={user.name}>
                    </Avatar>
                </ListItemIcon>
                <ListItemText primary="User Profile"
                              secondary={
                                <React.Fragment>
                                    <Link
                                        variant="subtitle2"
                                        onClick={() => {}}
                                        sx={{
                                            fontSize:'0.75rem'
                                        }}
                                    >
                                        {user.email}
                                    </Link>
                                </React.Fragment>
                              }
                              primaryTypographyProps={{ ...textStyle }} />
            </ListItemButton>
            <ListItemButton sx={{ ...itemStyle }} onClick={handleLogout}>
                <ListItemIcon>
                    {/*<Avatar sx={{ ...avatarStyle }}>*/}
                        {getIcon('exit', 'inherit', 'small', '', 400)}
                    {/*</Avatar>*/}
                </ListItemIcon>
                <ListItemText primary="Sign Out" primaryTypographyProps={{ ...textStyle }} />
            </ListItemButton>
        </List>
    );
}

const itemStyle = {
    borderRadius: '1rem',
    margin: (theme) => theme.spacing(1),
    paddingLeft: '0.5rem',
    '&:hover': {
        backgroundColor: (theme) => theme.palette.secondary.light,
    },
};

const avatarStyle = {
    backgroundColor: (theme) => theme.palette.primary.main,
    width: 32,
    height: 32,
    borderRadius: 2
};

const textStyle = {
    fontSize: '1rem',
    fontWeight: 'bold'
};
