
import {push, replace} from 'redux-first-history'
import { reverse } from 'named-urls';
import {useAuth0} from "@auth0/auth0-react";

let goToRoute = function (path, params = {}, query = null, replaceUrl = false) {
    return (dispatch) => {
        let routePath = reverse(path, params);
        if (query) {
            let search = new URLSearchParams(query);
            routePath = `${routePath}?${search.toString()}`;
        }
        if (replaceUrl) {
            dispatch(replace(routePath))
        }
        dispatch(push(routePath));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
};

let Logout = function () {
    const { logout } = useAuth0();
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });
}

export { goToRoute, Logout };
