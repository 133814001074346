// components/Source/index.js
import React, {useState} from 'react';
import {Avatar, Box, Button, IconButton, Tooltip, Typography} from "@mui/material";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import AvailableSourcesModal from "@/components/shared/Modals/availableSources";
import {goToRoute} from "@/store/actions/navigationActions";
import {AutoAwesome as AutoAwesomeIcon, Delete as DeleteIcon, Download as DownloadIcon} from "@mui/icons-material";
import {useAuth0} from "@auth0/auth0-react";
import ConfirmationDialog from "@/components/shared/ConfirmModal";



const Source = ({sources, availableSources, handleSourceAdd, handleRemoveSource}) => {
    const { user } = useAuth0();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletingSource, setDeletingSource] = useState({});


    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteConfirm = () => {
        // setDeleteDialogOpen(true);
        // console.log(deletingConversation);
        handleRemoveSource(deletingSource);
        setDeleteDialogOpen(false);
        setDeletingSource(null)
    };

    const source_columns = [
        {
            accessorKey: 'source_name',
            header: 'Name',
        },
        {
            accessorKey: 'source_type',
            header: 'Type',
            Cell: ({ cell }) => {
                const type = cell.getValue();
                return type.charAt(0).toUpperCase() + type.slice(1);
            },
        },
        {
            accessorKey: 'created_at',
            header: 'Added',
            Cell: ({ cell }) => {
                const date = new Date(cell.getValue());
                return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                }); // Format date to show only the date part
            },
        },
        {
            accessorKey: 'added_by_details',
            header: 'Added By',
            Cell: ({ cell }) => {
                const { name, email, picture } = cell.getValue();
                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={picture} alt={name} sx={{ width: 32, height: 32, mr: 1 }} />
                        <Box>
                            <Typography variant="body2">{name}</Typography>
                            <Typography variant="caption" color="textSecondary">{email}</Typography>
                        </Box>
                    </Box>
                );
            }
        }
    ];
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    }


    const source_table = useMaterialReactTable({
        columns: source_columns,
        data: sources, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        enableRowSelection: false,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner:"bottom",
        muiTablePaperProps: {
            elevation: 0
        },
        initialState: {
            pagination: { pageSize: 10, pageIndex: 0 },
            showGlobalFilter: true,
            density: 'spacious',
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 25],
            variant: 'outlined',
            elevation: 0
        },
        muiTopToolbarProps: {
            marginBottom: '2rem',
            marginTop: '2rem'
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow._id, //give each row a more useful id
        renderTopToolbarCustomActions:() => {
            return (
                <Box>
                    <Button variant="contained" color="secondary" onClick={handleModalOpen}>
                        Add New Source
                    </Button>
                </Box>
            )
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => {

            if (user.sub !== row.original.user_id) {
                return (<></>)
            }
            return (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                        {/*<Tooltip title="Download File" placement="top">*/}
                        {/*    <IconButton*/}
                        {/*        color="primary"*/}
                        {/*        onClick={() => {*/}
                        {/*            console.log(row);*/}
                        {/*            // dispatch(goToRoute(`/kb/${knowledgeBaseInfo?._id}/c/${row.id}`));*/}
                        {/*        }*/}
                        {/*        }*/}
                        {/*    >*/}
                        {/*        <DownloadIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        <Tooltip title="Delete this File" placement="top">
                            <IconButton
                                color="error"
                                onClick = {() => {
                                    setDeletingSource(row)
                                    setDeleteDialogOpen(true);

                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )

        },
    });

    return (
        <Box>
            <MaterialReactTable table={source_table}/>
            <AvailableSourcesModal
                open={modalOpen}
                handleClose={handleModalClose}
                sources={availableSources}
                handleSelectSources={handleSourceAdd}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                title="Confirm Delete"
                description="Are you sure you want to remove this Source?"
            />
        </Box>
    );
};

export default Source;