import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/material/styles";

const AvailableSourcesModal = ({ open, handleClose, sources, handleSelectSources, selectedRows, setSelectedRows }) => {
    const theme = useTheme();

    const columns = [
        {
            accessorKey: 'source_name',
            header: 'Name',
        },
        {
            accessorKey: 'source_type',
            header: 'Type',
        },
        {
            accessorKey: 'updated_at',
            header: 'Last-Modified',
            render: rowData => new Date(rowData.modified_at).toLocaleDateString(),
        },
    ];

    const table = useMaterialReactTable({
        columns,
        data: sources,
        enableRowSelection: true,
        enableFullScreenToggle: false,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner: "bottom",
        muiTablePaperProps: {
            elevation: 0
        },
        initialState: {
            pagination: { pageSize: 10, pageIndex: 0 },
            showGlobalFilter: true,
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 25],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow._id,
        renderTopToolbarCustomActions: ({ table }) => (
            <Button disabled={table.getSelectedRowModel().flatRows.length === 0} variant="contained" color="primary"
                    onClick={() => {
                        handleSelectSources(Object.keys(table.getState().rowSelection));
                        table.resetRowSelection();
                    }}
            >
                Add Selected source
            </Button>
        ),
    });

    useEffect(() => {
        console.log(table.getState().rowSelection);
    }, [table.getState().rowSelection]);

    const handleModalClose = () => {
        table.resetRowSelection();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleModalClose} fullWidth maxWidth="md" sx={{
                backgroundColor: theme.palette.background.default
        }}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.default
                }
            }}
        >
            <DialogTitle>
                Available Sources
                <IconButton
                    aria-label="close"
                    onClick={handleModalClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ height: '70vh' }}>
                <MaterialReactTable table={table} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AvailableSourcesModal;
