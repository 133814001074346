import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, useTheme} from '@mui/material';

const ConfirmationDialog = ({ open, onConfirm, onCancel, title, description }) => {
    const theme = useTheme();
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant='outlined' sx={{ color: theme.palette.secondary.dark }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus variant='contained'>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
