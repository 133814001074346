// ResponsiveDrawer/index.js
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Drawer, Toolbar, Divider, ListSubheader} from '@mui/material';
import { Typography } from '@mui/material';
import ChatHistory from './partials/ChatHistory';
import BaseSection from './partials/BaseSection';
import UserActions from './partials/UserActions';

const drawerWidth = 300;

function ResponsiveDrawer(props) {
    const {
        window,
        mobileOpen,
        handleDrawerClose,
        handleDrawerTransitionEnd,
        conversations,
        actionsList,
        onClickAction,
        shouldHighlight,
    } = props;

    const drawer = (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <Box sx={{ flexGrow: 0 }}>
            <Toolbar>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                    <img src='logo-black.svg' alt="Conversatio Logo" style={{width: '64px', height: '64px'}}/>
                    <Typography variant="h6" component="h1" ml={2} sx={{fontWeight: 700}}>
                        Conversatio
                    </Typography>
                </Box>
            </Toolbar>
                <Divider/>
                <BaseSection
                    actionsList={actionsList}
                    shouldHighlight={shouldHighlight}
                    open
                    onClickAction={onClickAction}
                />
                <Divider />
                <ListSubheader color="inherit" sx={{ fontWeight: '700' }}>
                    {'Chat History'}
                </ListSubheader>
            </Box>

            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                <ChatHistory chatHistory={conversations} open />
            </Box>
            <Divider />
            <Box sx={{ flexShrink: 0 }}>
                <UserActions />
            </Box>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    window: PropTypes.func,
    mobileOpen: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
    handleDrawerTransitionEnd: PropTypes.func.isRequired,
    conversations: PropTypes.array.isRequired,
    actionsList: PropTypes.array.isRequired,
    onClickAction: PropTypes.func.isRequired,
    shouldHighlight: PropTypes.func.isRequired,
};

export default ResponsiveDrawer;
