// actions
import { goToRoute, Logout } from '@/store/actions/navigationActions';

// utils
import routePaths from '@/utils/route-paths';


const getNavigationDrawerProps = (dispatch) => {
    const actionsList = [
        {
            icon: 'knowledge',
            label: 'Knowledge Bases',
            goto: () => {
                dispatch(goToRoute(routePaths.KNOWLEDGE));
            },
            path: routePaths.KNOWLEDGE,
            matchPath: 'knowledge'
        },
        {
            icon: 'source',
            label: 'Data Sources',
            goto: () => {
                dispatch(goToRoute(routePaths.DATA_SOURCES));
            },
            path: routePaths.DATA_SOURCES,
            matchPath: 'sources'
        },
        ]

    // User Profile and Logout actions
    const userActions = {
        profile: {
            icon: 'person',
            label: 'User Profile',
        },
        logout: {
            icon: 'exit',
            label: 'Logout',
            goto: () => {
                Logout(); // Dispatch logout action
            },
        },
    };


    return {
        actionsList,
        styleProps: {
            width: '100%',
        },
        userActions
    };
}

export default getNavigationDrawerProps;
