import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, MenuItem, FormControlLabel, Checkbox, IconButton, CircularProgress, useTheme, useMediaQuery, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddDatabaseModal = ({ open, handleClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [formData, setFormData] = useState({
        dbType: '',
        host: '',
        port: '',
        dbName: '',
        username: '',
        password: '',
        useSSL: false
    });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await addDatabaseSource(formData);
            console.log('Database added successfully');
        } catch (error) {
            console.error('Failed to add database:', error);
        }
        setLoading(false);
        handleClose();
    };

    const databaseTypes = [
        { value: 'redshift', label: 'Redshift' },
        { value: 'snowflake', label: 'Snowflake' },
        { value: 'postgresql', label: 'PostgreSQL' },
        { value: 'mysql', label: 'MySQL' }
        // Add other databases as needed
    ];

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="add-database-modal-title"
            aria-describedby="add-database-modal-description"
            fullScreen={fullScreen}

            sx={{
                overflowY: 'auto',

            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                marginTop: fullScreen && 8,
                marginBottom: fullScreen && 8,
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="add-database-modal-title" variant="h6" component="h2">
                    Add SQL Database
                </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label="Database Type"
                                value={formData.dbType}
                                onChange={handleInputChange}
                                fullWidth
                                name="dbType"
                                margin="normal"
                            >
                                {databaseTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Host"
                                value={formData.host}
                                onChange={handleInputChange}
                                fullWidth
                                name="host"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Port"
                                value={formData.port}
                                onChange={handleInputChange}
                                fullWidth
                                name="port"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Database Name"
                                value={formData.dbName}
                                onChange={handleInputChange}
                                fullWidth
                                name="dbName"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Username"
                                value={formData.username}
                                onChange={handleInputChange}
                                fullWidth
                                name="username"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                                fullWidth
                                name="password"
                                type="password"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={formData.useSSL} onChange={handleInputChange} name="useSSL" />}
                                label="Use SSL"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
                                Add Database
                            </Button>
                        </Grid>
                    </Grid>
                {loading && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

// Simulated API call function
async function addDatabaseSource(details) {
    console.log('Sending details to the server:', details);
    // Simulate an API call delay
    return new Promise((resolve) => setTimeout(resolve, 2000));
}

export default AddDatabaseModal;
