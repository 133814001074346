// components/Overview/Header.js
import React from 'react';
import {Box, Typography, IconButton, Chip, Tooltip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useTheme} from "@mui/material/styles";

const Header = ({ name, description, onEdit, status, source_type }) => {
    const theme = useTheme();

    const getStatusColor = (status) => {
        switch (status) {
            case 'processing':
                return theme.palette.warning.main;
            case 'complete':
                return theme.palette.success.main;
            case 'pending':
                return theme.palette.info.main;
            case 'error':
                return theme.palette.error.main;
            case 'file':
                return theme.palette.secondary.main
            case 'url':
                return theme.palette.secondary.light
            default:
                return theme.palette.text.primary;
        }
    };

    return (
        <Box sx={{ height: 300, overflowY: 'auto',  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="h4">{name}</Typography>
                    <Tooltip title="Edit" placement="top-end">
                        <IconButton onClick={onEdit} color='secondary' sx={{ ml: 2 }}><EditIcon /></IconButton>
                    </Tooltip>

                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Tooltip title="Knowledge Base Source" placement="top-end">
                        <Chip
                            label={source_type}
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                borderRadius: '0.5rem',
                                textTransform: 'capitalize',
                                fontWeight: '700',
                                mr: '1rem',
                                padding: '1rem 1.5rem'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Knowledge Base Status" placement="top-end">
                        <Chip
                            label={status}
                            sx={{
                                backgroundColor: getStatusColor(status),
                                color: theme.palette.getContrastText(getStatusColor(status)),
                                borderRadius: '0.5rem',
                                textTransform: 'capitalize',
                                fontWeight: '700',
                                padding: '1rem 1.5rem'
                            }}
                        />
                    </Tooltip>

                </Box>
            </Box>
            <Typography variant="body1">
                {description}
            </Typography>
        </Box>
    );
};

export default Header;
