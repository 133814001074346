import {IconButton, Paper, InputBase, useTheme} from "@mui/material";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import React, {useState} from "react";


export const ChatInput = ({handleMessageSend, handleKeyPress, handleInputChange, input}) => {
    const [value, setValue] = useState('')

    const theme = useTheme();

    const handleSubmit = () => {
        handleMessageSend(value);
        setValue('');
    }
    return (

        <Paper
            component="form"
            elevation={3}
            sx={{
                p: '8px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '75%',
                borderRadius: '1rem',
                border: '1px solid ',
                margin: 'auto',
                color: theme.palette.primary.light
        }}
        >
            <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="Ask Knowledge Base"
                inputProps={{ 'aria-label': 'Ask Knowledge Base' }}
                onChange={handleInputChange}
                value={input}
                onKeyPress={handleKeyPress}
            />
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="send"
                onClick={handleSubmit}
                disabled={input.length <= 0}
                color='primary'
            >
                <SendRoundedIcon />
            </IconButton>
        </Paper>
    )
}