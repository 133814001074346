import axios from 'axios';

// Function to create a new axios instance with interceptors
export function createAxiosInstance(baseURL, accessToken, content_type) {
    let instance = axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': content_type ? content_type : 'application/json',
            ...(accessToken && { Authorization: `Bearer ${accessToken}` })
        },
    });

    instance.interceptors.request.use(
        function (config) {
            // Add the Authorization header on each request
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    instance.interceptors.response.use(
        function (response) {
            // Handle responses
            return response;
        },
        function (error) {

            // Handle errors
            return Promise.reject(error);
        },
    );

    return instance;
}
