// components/Overview/SharingDetails.js
import React from 'react';
import {Box, Avatar, Typography, IconButton, Divider} from '@mui/material';
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";


const SharingDetails = ({ sharedUsers, handleRemoveAccess, owner}) => {
    console.log(sharedUsers);
    return (
        <Box sx={{ height: 300}}>
            <Typography variant="h6" component="div" sx={{ mb: 2 }}>Shared with</Typography>
            <Divider/>
            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                {sharedUsers?.slice(0, 5).map((user, index) => (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={2}>
                        <Box display="flex" alignItems="center" gap={4}>
                            <Avatar sx={{ width: 32, height: 32 }} src={user.picture} alt={user.name} />
                            <Box>
                                <Typography variant="subtitle2">{user.name}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {user.email}
                                </Typography>
                            </Box>
                        </Box>
                        {owner === true && <IconButton onClick={() => {
                            handleRemoveAccess(user.email)
                        }} color='secondary' ml={2}><PersonRemoveIcon /></IconButton>}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SharingDetails;
