import React from 'react';

// material
import { Box, Typography } from '@mui/material';
import {useTheme} from '@mui/material'

import { trefoil } from 'ldrs'

trefoil.register()


export default function Loader({message}) {
    const theme = useTheme();
    return (
        <Box
            width="100%"
            height="80vh"
            display="flex"
            alignItems={'center'}
            justifyContent="center">
            <Box position="relative" display="flex" sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.primary.main,
                zIndex: '3',
                position: 'absolute',
            }}>
                {/*<CircularProgress size={60} color="primary" thickness={2} />*/}

                <l-trefoil
                    size="70"
                    stroke="5"
                    stroke-length="0.15"
                    bg-opacity="0.1"
                    speed="1.5"
                    color="#F20C0C"
                />
                {message ? (
                    <Typography variant="subtitle2" fontSize={16} fontWeight={600} mt={2}>{message}</Typography>

                ) : ""}
                {/*<Typography variant="body1" mt={2}>Loading</Typography>*/}

                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                </Box>

            </Box>
        </Box>
    );
}
