import AWS from 'aws-sdk';
import constants from '@/constants';

// Initialize AWS S3 configuration
AWS.config.update({
    accessKeyId: constants.accessKeyId,
    secretAccessKey: constants.secretAccessKey,
    region: constants.region,
});

const s3 = new AWS.S3();

export const getSignedUrl = async (documentKey) => {
    const params = {
        Bucket: constants.bucketName,
        Key: documentKey,
        Expires: 60 * 5, // URL expires in 5 minutes
        ResponseContentDisposition: 'inline',
    };

    try {
        return await s3.getSignedUrlPromise('getObject', params);
    } catch (error) {
        console.error('Error generating signed URL:', error);
        throw error;
    }
};
