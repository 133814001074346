// Section1.js
import React from 'react';
import {List, ListItemButton, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import getIcon from "@/utils/get-icon";
import {useTheme} from "@mui/material/styles";

export default function BaseSection({ actionsList, shouldHighlight, open, onClickAction }) {
    const theme = useTheme();
    return (
        <List>
            {actionsList.map((item, index) => (
                <Tooltip title={open ? '' : item.label} placement="right" key={item.label}>
                <ListItemButton
                    key={index}
                    onClick={() => onClickAction(item)}
                    sx={{
                        borderRadius: '1rem',
                        margin: (theme) => theme.spacing(1),
                        paddingLeft: '1rem',
                        paddingRight: '0.7rem',
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.light,
                        },
                        // ...(shouldHighlight(item) ? { backgroundColor: theme.palette.primary.light } : {})
                    }}
                >
                    <ListItemIcon sx={{
                        color: shouldHighlight(item) ? (theme) => theme.palette.primary.main : 'inherit',
                        minWidth: '0px'
                    }}>
                        {/*<Avatar sx={{ backgroundColor: theme.palette.primary.main, width: 32, height: 32, borderRadius: 2 }}>*/}
                            {getIcon(item.icon, 'inherit', 'small', '', 400)}
                        {/*</Avatar>*/}
                    </ListItemIcon>
                    <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: shouldHighlight(item) ? (theme) => theme.palette.primary.main : 'inherit',
                        }}
                        sx={{
                            paddingLeft: '1rem'
                        }}
                    />
                </ListItemButton>
                </Tooltip>
            ))}
        </List>
    );
}
