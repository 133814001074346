import {configureStore} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history';

import createRootReducer from './reducers'


import {createReduxHistoryContext} from "redux-first-history";

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });



export const  configureAppStore = (preloadedState) =>  {
    return configureStore({
        reducer: createRootReducer(routerReducer),

        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
        preloadedState

    });
}

export default createReduxHistory