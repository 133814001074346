import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import Root from "@/components/Root";

import { store, history } from './store';
import * as serviceWorker from "./serviceWorker";




const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
        <Root store={store} history={history} />
);

serviceWorker.unregister();