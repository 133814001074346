// components/Overview/StatsCards.js
import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box, Icon, Avatar
} from '@mui/material';

const StatsCards = ({ stats }) => {


    return (
        <Grid container item spacing={6}>
            {stats.map((stat, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card sx={{ flex: '1 1 calc(20% - 16px)', minWidth: 200, borderRadius: 4 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {stat.type === 'lastUpdatedBy' || stat.type === 'owner' ? (
                                <Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                                        <Avatar src={stat.pic} alt={stat.value} sx={{ width: 24, height: 24, mb: 1 }} />
                                        <Box ml={2}>
                                            <Typography variant="body1" sx={{ lineHeight: '1rem' }}>{stat.value}</Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ lineHeight: '1rem' }}>{stat.email}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Icon color="secondary" sx={{ marginRight: '1rem' }}>{stat.icon}</Icon>
                                        <Typography variant="body2" align="center">{stat.title}</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 600 }}>{stat.value}</Typography>
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Icon color="secondary" sx={{ marginRight: '1rem' }}>{stat.icon}</Icon>
                                        <Typography variant="body2" align="center">{stat.title}</Typography>
                                    </Box>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default StatsCards;




