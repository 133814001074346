// components/Conversations/index.js
import React, {useState} from 'react';
import {Avatar, Box, Button, IconButton, Tooltip} from "@mui/material";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    AutoAwesome as AutoAwesomeIcon,
} from '@mui/icons-material';
import {useTheme} from "@mui/material/styles";
import {goToRoute} from "@/store/actions/navigationActions";
import {useDispatch} from "react-redux";
import ConfirmationDialog from "@/components/shared/ConfirmModal";




const Conversation = ({conversations, handleDeleteConversation, knowledgeBaseInfo, handleRename}) => {
    const dispatch = useDispatch();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletingConversation, setDeletingConversation] = useState({});

    const handleDeleteConfirm = () => {
        // setDeleteDialogOpen(true);
        // console.log(deletingConversation);
        handleDeleteConversation(deletingConversation);
        setDeleteDialogOpen(false);
        setDeletingConversation(null)
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    }

    const conversation_columns = [
        {
            accessorKey: 'title',
            header: 'Title',
        },
        {
            accessorKey: 'messages',
            header: 'Messages',
            enableEditing: false,
            Edit: () => null,
        },
        {
            accessorKey: 'created_at',
            header: 'Created On',
            enableEditing: false,
            Edit: () => null,
            Cell: ({ cell }) => {
                const date = new Date(cell.getValue());
                return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                }); // Format date to show only the date part
            },
        }
    ];

    const table = useMaterialReactTable({
        columns: conversation_columns,
        data: conversations, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        enableRowSelection: false,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner:"bottom",
        muiTablePaperProps: {
            elevation: 0
        },
        initialState: {
            pagination: { pageSize: 10, pageIndex: 0 },
            showGlobalFilter: true,
            density: 'spacious',
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 25],
            variant: 'outlined',
            elevation: 0
        },
        muiTopToolbarProps: {
            marginBottom: '2rem',
            marginTop: '2rem'
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow._id, //give each row a more useful id
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <Tooltip title="Continue conversation" placement="top">
                <IconButton
                    color="primary"
                    onClick={() => {
                        console.log(row)
                        dispatch(goToRoute(`/kb/${knowledgeBaseInfo?._id}/c/${row.id}`));
                    }
                }
                    >
                    <AutoAwesomeIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Rename" placement="top">
                <IconButton
                    color='secondary'
                    onClick={() => {
                        table.setEditingRow(row);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete this conversation" placement="top">
                <IconButton
                    color="error"
                    onClick = {() => {
                        setDeletingConversation(row)
                        setDeleteDialogOpen(true);

                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Box>
        ),
        onEditingRowSave: ({ table, values, row }) => {

            console.log(row)
            handleRename(values, row.id);
            table.setEditingRow(null); //exit editing mode
        },

    });

    return (
        <Box>
            <MaterialReactTable table={table}/>
            <ConfirmationDialog
                open={deleteDialogOpen}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                title="Confirm Delete"
                description="Are you sure you want to delete this Conversation?"
            />
        </Box>
    );
};


export default  Conversation;