// FileSource.js
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
    useMaterialReactTable,
    MaterialReactTable
} from 'material-react-table';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddFilesModal from "@/components/shared/Modals/addFiles";


const columns = [
    {
        accessorKey: '_id',
        header: 'Id',
    },
    {
        accessorKey: 'source_name',
        header: 'Name',
    },
    {
        accessorKey: 'source_type',
        header: 'Type',
    },
    {
        accessorKey: 'updated_at',
        header: 'Last-Modified',
        render: rowData => new Date(rowData.modified_at).toLocaleDateString(),
    },
];

const FileSource = ({ selectedFiles, setSelectedFiles, files }) => {
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [rowSelection, setRowSelection] = useState({});

    const handleClick = () => {
        setFileModalOpen(true);
    };

    useEffect( () => {
        // console.log(selectedFiles);
    }, [selectedFiles]);


    const table = useMaterialReactTable({
        columns,
        data: files, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        enableRowSelection: true,
        enableSorting: true,
        enableDensityToggle: false,
        enableHiding: false,
        enableColumnActions: false,
        positionToolbarAlertBanner:"bottom",
        muiTablePaperProps: {
            elevation: '0'
        },
        initialState: {
            pagination: { pageSize: 10, pageIndex: 0 },
            showGlobalFilter: true,
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 25],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
        getRowId: (originalRow) => originalRow._id, //give each row a more useful id
        onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
        state: { rowSelection },
        renderTopToolbarCustomActions: () => {
            return (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        sx={{ ml: '1rem'}}
                        onClick={handleClick}
                    >
                        Upload Files
                    </Button>
        )
        }
    });


    useEffect(() => {
        setSelectedFiles(Object.keys(rowSelection))
    }, [rowSelection]);


    return (
        <>
            <MaterialReactTable table={table}/>
            <AddFilesModal open={fileModalOpen} handleClose={() => setFileModalOpen(false)} />
        </>

    );
};

export default FileSource;
