import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {addUrls} from "@/api/dataSource";
import {appendSourcesData} from "@/store/slices/sourceSlice";
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";

const AddURLsModal = ({ open, handleClose }) => {
    const [urls, setUrls] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const handleUrlsChange = (event) => {
        setUrls(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const urlList = urls.split('\n').filter(url => url.trim() !== ''); // Split by new line and filter out empty lines
        const payload = {
            urls: urlList
        }
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await addUrls(accessToken, payload);
            const responseData = response.data;
            dispatch(appendSourcesData(responseData)); // Update the state with response data
            setLoading(false);
            handleClose(); // Close the modal after uploading
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
        setLoading(false);
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="add-urls-modal-title"
            aria-describedby="add-urls-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                overflowY: 'auto',
                backgroundColor: 'background.paper',
                boxShadow: 24,
                p: 4,
                pt: 2,
                pb: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="add-urls-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Add URLs
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Add each URL on a separate line.
                </Typography>
                <TextField
                    multiline
                    rows={10}
                    fullWidth
                    variant="outlined"
                    value={urls}
                    onChange={handleUrlsChange}
                    placeholder="Enter each url in a new line"
                />
                <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
                    Add
                </Button>
                {loading && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Modal>
    );
};


export default AddURLsModal;
